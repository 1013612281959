import { styled } from '@mui/material';

const StyledVerticalNavBgColorContainer = styled('div')`
  position: relative;
  block-size: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--mui-palette-background-dark);
`;

export default StyledVerticalNavBgColorContainer;
