import { Button, Stack, Typography } from '@mui/material';
import type { BackupSchedule } from '@repo/api-gw-sdk';

import { StepContainer } from '@/components/wizard/StepContainer';
import type { StepProps } from '@/components/wizard/StepProps';

import type { BackupPolicyEditState } from './BackupPolicyCreationFlow';
import { BackupPolicySchedule } from './BackupPolicySchedule';

export const BackupPolicySettings = (
  props: StepProps<BackupPolicyEditState>
) => {
  const addSchedule = () => {
    const newState = {
      ...props.state,
    };

    newState.schedules = [
      ...(newState.schedules ?? []),
      {
        id: crypto.randomUUID(),
        policyId: '',
        duration: 24 * 60, // 24 hours default
        backupRetention: 30,
        vaultId: '',
        windows: '0 0 * * *',
      },
    ];

    props.setState(newState);
  };

  const updateSchedule = (schedule: BackupSchedule) => {
    const schedules =
      props.state.schedules?.map((s) =>
        s.id === schedule.id ? schedule : s
      ) ?? [];

    props.setState({ ...props.state, schedules });
  };

  const removeSchedule = (schedule: BackupSchedule) => {
    if (props.state.schedules?.length === 1) return;
    props.setState({
      ...props.state,
      schedules: props.state.schedules?.filter((s) => s.id !== schedule.id),
    });
  };

  const duplicateSchedule = (schedule: BackupSchedule) => {
    props.setState({
      ...props.state,
      schedules: [
        ...(props.state.schedules ?? []),
        { ...schedule, id: crypto.randomUUID() },
      ],
    });
  };

  return (
    <>
      <StepContainer
        error={props.error}
        stepperLabels={props.stepperLabels}
        stepperIndex={2}
        onBackClick={props.back}
        canGoNext={() => true}
        nextButtonText='Save'
        onNextClick={() => {
          props.finish();
        }}
      >
        <Stack sx={{ margin: '100px' }}>
          <Typography variant='body1' sx={{ mb: '16px' }}>
            Choose how often and where you want to back up to:
          </Typography>
          {props.state.schedules?.map((schedule) => (
            <BackupPolicySchedule
              deleteAllowed={(props.state.schedules?.length ?? 0) > 1}
              schedule={schedule}
              key={schedule.id}
              onUpdate={updateSchedule}
              onDuplicate={duplicateSchedule}
              onDelete={removeSchedule}
            />
          ))}
          <div style={{ marginTop: '16px' }}>
            <Button
              variant='outlined'
              onClick={addSchedule}
              sx={{ borderColor: 'transparent' }}
              data-testid='create-backup-policy-add-backup-schedule-btn'
            >
              <i className='material-symbols-add-rounded mr-[8px]' />
              Add another
            </Button>
          </div>
        </Stack>
      </StepContainer>
    </>
  );
};
