import(/* webpackMode: "eager", webpackExports: ["SettingsProvider"] */ "/home/runner/work/eon-service/eon-service/frontend/apps/eon/src/@core/contexts/settingsContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/eon-service/eon-service/frontend/apps/eon/src/@layouts/LayoutWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VerticalNavProvider"] */ "/home/runner/work/eon-service/eon-service/frontend/apps/eon/src/@menu/contexts/verticalNavContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthChecker"] */ "/home/runner/work/eon-service/eon-service/frontend/apps/eon/src/app/(dashboard)/AuthChecker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LeftSidePanel","RightSidePanel"] */ "/home/runner/work/eon-service/eon-service/frontend/apps/eon/src/components/layout/sidePanel/sidePanel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/eon-service/eon-service/frontend/apps/eon/src/components/layout/vertical/Navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/eon-service/eon-service/frontend/apps/eon/src/components/theme/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EnvironmentProvider"] */ "/home/runner/work/eon-service/eon-service/frontend/apps/eon/src/contexts/environmentContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NextAuthProvider"] */ "/home/runner/work/eon-service/eon-service/frontend/apps/eon/src/contexts/nextAuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RolesProvider"] */ "/home/runner/work/eon-service/eon-service/frontend/apps/eon/src/contexts/rolesContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/home/runner/work/eon-service/eon-service/frontend/apps/eon/src/contexts/userContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WorkspaceProvider"] */ "/home/runner/work/eon-service/eon-service/frontend/apps/eon/src/contexts/workspaceContext.tsx");
