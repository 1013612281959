import Link from 'next/link';
import { useEffect, useRef } from 'react';

import useVerticalNav from '@menu/hooks/useVerticalNav';

import { EONLogo } from './eonLogo';

const Logo = () => {
  // Refs
  const logoTextRef = useRef<HTMLSpanElement>(null);

  // Hooks
  const { isHovered, isCollapsed } = useVerticalNav();

  useEffect(() => {
    if (!isCollapsed) {
      return;
    }

    if (logoTextRef?.current) {
      if (isCollapsed && !isHovered) {
        logoTextRef.current.classList.add('hidden');
      } else {
        logoTextRef.current.classList.remove('hidden');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered, isCollapsed]);

  // You may return any JSX here to display a logo in the sidebar header
  return (
    <Link href={'/'} className='flex items-center'>
      <EONLogo />
    </Link>
  );
};

export default Logo;
