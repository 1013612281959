import {
  MenuItem,
  Select,
  Stack,
  styled,
  Typography,
  Box,
  Button,
  Popover,
  TextField,
} from '@mui/material';
import {
  type MinRetentionRule,
  MinRetentionRuleFrequencyEnum,
  type ControlRules,
} from '@repo/api-gw-sdk';
import { useState, useRef } from 'react';

import OptionMenu from '@/@core/components/option-menu';

export const retentionMapping = [
  {
    type: MinRetentionRuleFrequencyEnum.Daily,
    text: 'Daily backups',
    unit: 'days',
  },
  {
    type: MinRetentionRuleFrequencyEnum.Weekly,
    text: 'Weekly backups',
    unit: 'weeks',
  },
  {
    type: MinRetentionRuleFrequencyEnum.Monthly,
    text: 'Monthly backups',
    unit: 'months',
  },
  {
    type: MinRetentionRuleFrequencyEnum.Annual,
    text: 'Annual backups',
    unit: 'years',
  },
];

const ScheduleContainer = styled('div')(() => ({
  margin: '8px 0',
}));

const Schedule = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: '16px',
  display: 'inline-flex',
}));

const ScheduleSelect = styled(Select)(({ theme }) => ({
  '&::before, &::after': {
    border: 'none',
  },
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
  },
  '& div.MuiSelect-select': {
    padding: '5px',
  },
  '& .MuiSelect-select .MuiTypography-root': {
    marginRight: '16px',
  },
  '& i': {
    marginLeft: '8px',
    right: 0,
    border: '1px solid green',
  },
  '& .MuiList-root .MuiMenuItem-root p': {
    fontWeight: '400',
  },
  '& .MuiTypography-root': {
    fontWeight: '600',
    color: theme.palette.mode === 'dark' ? 'white' : '#272742',
  },
}));

function ScheduleEditor({
  schedule,
  onChange,
  onDelete,
  onDuplicate,
}: {
  schedule: MinRetentionRule;
  onChange: (schedule: MinRetentionRule) => void;
  onDelete: () => void;
  onDuplicate: () => void;
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { unit } =
    retentionMapping.find(({ type }) => type === schedule.frequency) ||
    retentionMapping[0];
  return (
    <ScheduleContainer>
      <Schedule direction='row' alignItems='center' gap='12px'>
        <ScheduleSelect
          value={schedule.frequency}
          onChange={(e) =>
            onChange({
              ...schedule,
              frequency: e.target.value as MinRetentionRuleFrequencyEnum,
            })
          }
          variant='standard'
        >
          {retentionMapping.map((m) => (
            <MenuItem key={m.type} value={m.type}>
              <Typography variant='body1'>{m.text}</Typography>
            </MenuItem>
          ))}
        </ScheduleSelect>
        <Typography variant='body1'>are retained for at least</Typography>
        <Button ref={anchorRef} onClick={() => setOpen(true)}>
          {schedule.minimumRetention} {unit}
          <i className='material-symbols-arrow-drop-down-rounded text-xl' />
        </Button>
        <Popover
          open={open}
          anchorEl={anchorRef.current}
          onClose={() => setOpen(false)}
        >
          <Box className='flex flex-col p-[24px] gap-[24px] w-[300px]'>
            <Box className='flex items-center justify-between'>
              How many {unit}?{' '}
              <TextField
                type='number'
                inputProps={{
                  min: 1,
                  step: 1,
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                }}
                sx={{
                  width: 80,
                  '& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button':
                    {
                      WebkitAppearance: 'none',
                      margin: 0,
                    },
                }}
                value={schedule.minimumRetention}
                onChange={(e) =>
                  onChange({ ...schedule, minimumRetention: +e.target.value })
                }
              />
            </Box>
          </Box>
        </Popover>
        <OptionMenu
          iconClassName='text-textPrimary'
          icon='material-symbols-more-horiz'
          options={[
            { text: 'Duplicate' },
            {
              text: 'Delete',
            },
          ]}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onOptionSelected={(option: any): void => {
            switch (option.text) {
              case 'Duplicate':
                return onDuplicate();
              case 'Delete':
                return onDelete();
            }
          }}
        />
      </Schedule>
    </ScheduleContainer>
  );
}

export function MinimumRetentionEditor({
  rules,
  onChange,
}: {
  rules: ControlRules;
  onChange: (rules: ControlRules) => void;
}) {
  const schedules = (rules.minimumRetention ?? []).filter((r) => r.enabled);
  const updateSchedule = (schedule: MinRetentionRule, index: number) => {
    const newSchedules = [...schedules];
    newSchedules[index] = schedule;
    onChange({
      ...rules,
      minimumRetention: newSchedules,
    });
  };

  return (
    <Box className='my-[12px]'>
      {schedules?.map((schedule, i) => (
        <ScheduleEditor
          key={i}
          schedule={schedule}
          onChange={(schedule) => updateSchedule(schedule, i)}
          onDelete={() =>
            onChange({
              ...rules,
              minimumRetention: schedules.filter((s, j) => j !== i),
            })
          }
          onDuplicate={() =>
            onChange({ ...rules, minimumRetention: [...schedules, schedule] })
          }
        />
      ))}
      <div style={{ marginTop: '16px' }}>
        <Button
          variant='outlined'
          onClick={() =>
            onChange({
              ...rules,
              minimumRetention: [
                ...schedules,
                {
                  enabled: true,
                  minimumRetention: 1,
                  frequency: MinRetentionRuleFrequencyEnum.Daily,
                },
              ],
            })
          }
          sx={{ borderColor: 'transparent' }}
        >
          <i className='material-symbols-add-rounded mr-[8px]' />
          Add another
        </Button>
      </div>
    </Box>
  );
}
