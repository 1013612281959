import { Stack, Tooltip, Typography } from '@mui/material';

import { useTranslation } from '@/i18n/client';
import {
  createCombineCondition,
  isValueCondition,
  type Condition,
  type ValueCondition,
} from '@/types/advanceFilter';

import * as properties from '../queryBuilder/properties';
import { Icon } from '../shared/icon';

export const FiltersBar = ({
  columnFilters,
  onChange,
}: {
  columnFilters: Condition;
  onChange: (condition: Condition) => void;
}) => {
  const { t } = useTranslation('properties');

  const conditions = collectValueConditions(columnFilters);
  if (!conditions.length) {
    return null;
  }

  return (
    <Stack
      className='p-[12px] w-[70%]'
      direction={'row'}
      flexWrap={'wrap'}
      gap={'8px'}
    >
      {conditions.map((condition) =>
        condition.value.map((val) => {
          const property = Object.values(properties).find(
            (x) => x.name === condition.property
          );

          const Badge = property?.filter?.Badge;

          return (
            <Tooltip
              key={condition.property + val}
              title={t(property?.displayNameKey || '')}
            >
              <Stack
                direction={'row'}
                className='py-[4px] px-[12px] inline-flex rounded-[4px]'
                sx={{ border: '1px solid #272727' }}
                alignItems={'center'}
              >
                <Typography
                  component={'span'}
                  className='font-semibold cursor-default text-ellipsis overflow-hidden	whitespace-nowrap	max-w-[200px]'
                >
                  {Badge && <Badge conditionValue={val} />}
                  {!Badge && val}
                </Typography>
                <i
                  className='material-symbols-close-rounded text-[18px] ml-[4px] cursor-pointer'
                  onClick={() => {
                    const updatedCondition = condition.value.filter(
                      (x) => x !== val
                    );

                    onChange(
                      replaceValueCondition(
                        columnFilters,
                        condition,
                        updatedCondition.length
                          ? { ...condition, value: updatedCondition }
                          : undefined
                      )
                    );
                  }}
                />
              </Stack>
            </Tooltip>
          );
        })
      )}
      <Tooltip title='Clear all'>
        <Icon
          iconClass='material-symbols-filter-alt-off-outline-rounded'
          onClick={() => onChange(createCombineCondition())}
        />
      </Tooltip>
    </Stack>
  );
};

function replaceValueCondition(
  filters: Condition,
  oldCondition: Condition,
  newCondition: Condition | undefined
) {
  if (isValueCondition(filters)) {
    return filters;
  }

  return {
    ...filters,
    conditions: filters.conditions.reduce<Condition[]>((agg, val) => {
      if (val === oldCondition) {
        if (newCondition) {
          agg.push(newCondition);
        }
      } else {
        agg.push(replaceValueCondition(val, oldCondition, newCondition));
      }

      return agg;
    }, []),
  };
}

function collectValueConditions(condition: Condition): ValueCondition[] {
  return isValueCondition(condition)
    ? [condition]
    : condition.conditions.flatMap(collectValueConditions);
}
