import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Typography,
  FormControlLabel,
  Checkbox,
  ListItem,
  Radio,
  styled,
} from '@mui/material';
import type { User } from '@repo/api-gw-sdk';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { useRoles } from '@/contexts/useRoles';
import { useDAL } from '@/data/dal';

import { Loader } from '../layout/loading';

export const ProjectRolesComponent = ({
  roleToProjectsMappings,
  onChange,
}: {
  roleToProjectsMappings: User['roleToProjectsMappings'];
  onChange: (roleToProjectsMappings: User['roleToProjectsMappings']) => void;
}) => {
  const { roles } = useRoles();
  const hasProjectRoles = Object.keys(roleToProjectsMappings).some(
    (roleId) => roles.find((role) => role.id === roleId)?.isProjectRole
  );

  const [mode, setMode] = useState(hasProjectRoles ? 'project' : 'account');

  useEffect(() => {
    setMode(hasProjectRoles ? 'project' : 'account');
  }, [hasProjectRoles]);

  const changeMode = (newMode: 'account' | 'project') => {
    if (newMode !== mode) {
      setMode(newMode);
      onChange({});
    }
  };

  const dal = useDAL();
  const { body: allProjects, isLoading } = dal.projects.get();

  if (!allProjects || isLoading) {
    return (
      <FormControl size='small' className='w-full mt-[24px]'>
        <Loader />
      </FormControl>
    );
  }

  return (
    <Box className='w-full mt-[24px]'>
      <OptionListItem onClick={() => changeMode('account')}>
        <FormControlLabel
          checked={mode === 'account'}
          onClick={() => changeMode('account')}
          control={<Radio />}
          label='Account Roles'
        />
        <Box
          className={classNames('w-full content', {
            expanded: mode === 'account',
          })}
        >
          <RolesComponent
            hideTitle
            projectId={undefined}
            roleToProjectsMappings={roleToProjectsMappings}
            onChange={onChange}
          />
          <Divider className='my-[8px]' />
        </Box>
      </OptionListItem>
      <OptionListItem onClick={() => changeMode('project')}>
        <FormControlLabel
          checked={mode === 'project'}
          onClick={() => changeMode('project')}
          control={<Radio />}
          label='Project Roles'
        />
        <Box
          className={classNames('w-full content', {
            expanded: mode === 'project',
          })}
        >
          {allProjects.projects.map((project, index) => {
            return (
              <Box key={project.id}>
                <FormLabel className='font-medium'>{project.name}</FormLabel>
                <RolesComponent
                  hideTitle
                  projectId={project.id}
                  roleToProjectsMappings={roleToProjectsMappings}
                  onChange={(roleToProjectsMappings) => {
                    onChange(roleToProjectsMappings);
                  }}
                />
                {index < allProjects.projects.length - 1 && (
                  <Divider className='my-[8px]' />
                )}
              </Box>
            );
          })}
        </Box>
      </OptionListItem>
    </Box>
  );
};

export const RolesComponent = ({
  projectId,
  roleToProjectsMappings,
  onChange,
}: {
  hideTitle?: boolean;
  projectId: string | undefined;
  roleToProjectsMappings: User['roleToProjectsMappings'];
  onChange: (roleToProjectsMappings: User['roleToProjectsMappings']) => void;
}) => {
  const { roles: allRoles } = useRoles();
  const relevantRoles = allRoles.filter((x) =>
    projectId ? x.isProjectRole : !x.isProjectRole
  );

  const checkedRoles = Object.keys(roleToProjectsMappings).filter((x) =>
    projectId
      ? roleToProjectsMappings[x]?.ids?.includes(projectId)
      : roleToProjectsMappings[x]
  );

  return (
    <Box className={`w-full mt-[8px]`}>
      <FormControl size='small' className='w-full'>
        {relevantRoles.map((role) => {
          return (
            <FormControlLabel
              key={role.id}
              control={
                <Checkbox
                  checked={checkedRoles.includes(role.id)}
                  onChange={(event, checked) => {
                    if (projectId) {
                      const updatedProjects = (
                        roleToProjectsMappings[role.id]?.ids?.filter(
                          (x) => x !== projectId
                        ) || []
                      ).concat(checked ? [projectId] : []);

                      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                      delete roleToProjectsMappings[role.id];
                      if (updatedProjects.length) {
                        roleToProjectsMappings[role.id] = {
                          ids: updatedProjects,
                        };
                      }
                    } else {
                      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                      delete roleToProjectsMappings[role.id];
                      if (checked) {
                        roleToProjectsMappings[role.id] = {};
                      }
                    }

                    onChange({ ...roleToProjectsMappings });
                  }}
                />
              }
              label={<Typography>{role.name}</Typography>}
            />
          );
        })}
      </FormControl>
    </Box>
  );
};

const OptionListItem = styled(ListItem)({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  alignItems: 'flex-start',
  padding: '16px',
  borderRadius: 'var(--mui-shape-borderRadius)',
  border: '2px solid var(--mui-palette-primary-main)',
  marginBottom: '8px',
  gap: 0,

  '.content': {
    height: 0,
    overflow: 'hidden',

    '> :first-child': {
      marginTop: '8px',
    },

    '&.expanded': {
      height: 'auto',
      cursor: 'default',
    },
  },
});
