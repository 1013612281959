'use client';

// React Imports
import type { ReactElement } from 'react';

// Type Imports
import useLayoutInit from '@core/hooks/useLayoutInit';
import type { SystemMode } from '@core/types';

// Hook Imports

interface LayoutWrapperProps {
  systemMode: SystemMode;
  verticalLayout: ReactElement;
}

const LayoutWrapper = (props: LayoutWrapperProps) => {
  const { systemMode, verticalLayout } = props;

  useLayoutInit(systemMode);

  // Return the layout based on the layout context
  return (
    <div data-testid='authenticated-layout' className='flex flex-col flex-auto'>
      {verticalLayout}
    </div>
  );
};

export default LayoutWrapper;
