import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import { InventoryResourceBackupStatusEnum } from '@repo/api-gw-sdk';

import { getParentPath } from '@/app/(dashboard)/search/searchUtils';
import { BackupStatusIcon } from '@/components/backupStatus/backupStatusIcon';
import { SingleLevelFileExplorer } from '@/components/explorer/SingleLevelFileExplorer';
import {
  resourceId,
  resourceName,
  resourceType,
} from '@/components/queryBuilder/properties';
import { PropertyGroups } from '@/components/queryBuilder/propertyGroups';
import {
  ActionBarButton,
  ActionsBar,
  ActionsToolBar,
} from '@/components/shared/actionbar';
import { BackupStatuses } from '@/data/inventory/data';

import { BackupStatusContent } from './backupStatusContent';
import { SidePanelPropsTable } from './shared';

import type { InstancePanelProps } from '../../instancePanel';

export const OverviewTab = <
  T extends {
    id?: string;
    resourceId: string;
    resourceName?: string;
    path?: string;
    backupStatus?: InventoryResourceBackupStatusEnum;
  },
>(
  props: InstancePanelProps<T>
) => {
  return (
    <>
      <Stack className='pt-[40px] pb-[16px]'>
        <Typography className='px-[40px]' fontSize={'20px'}>
          {props.entity.resourceName}
        </Typography>
        <SidePanelPropsTable className='mt-[16px] mx-[40px]'>
          <tbody>
            {[
              props.fields.find((x) => x.id === resourceId.name)!,
              props.fields.find((x) => x.id === resourceType.name)!,
            ]
              .filter((x) => !!x)
              .map((x) => ({
                id: x.id,
                title: x.title,
                group: x.group,
                value: x.value(props.entity),
              }))
              .filter((x) => x.value)
              .map((x) => (
                <tr key={x.id}>
                  <td className='props-col'>{x.title(props.entity)}</td>
                  <td>{x.value}</td>
                </tr>
              ))}
          </tbody>
        </SidePanelPropsTable>
        {!!props.actions.length && (
          <ActionsBar
            position='static'
            className='mb-0'
            sx={{
              borderTop: '1px solid var(--border-color)',
              paddingInline: '16px',
            }}
          >
            <Container maxWidth='xl'>
              <ActionsToolBar disableGutters>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  {props.actions.map((action, i) => (
                    <ActionBarButton
                      key={i}
                      onClick={() => action.execute(props.entity)}
                    >
                      {action.icon && (
                        <i
                          className={`${action.icon} text-[18px] mr-[5px] text-primary align-middle`}
                        />
                      )}

                      {action.title}
                    </ActionBarButton>
                  ))}
                </Box>
              </ActionsToolBar>
            </Container>
          </ActionsBar>
        )}
      </Stack>

      {props.entity.backupStatus && (
        <Accordion disableGutters defaultExpanded={true}>
          <AccordionSummary>Backup status</AccordionSummary>
          <AccordionDetails>
            <Stack
              direction={'row'}
              alignItems={'center'}
              className='mb-[24px]'
            >
              <BackupStatusIcon backupStatus={props.entity.backupStatus} />
              <Typography
                fontSize={'16px'}
                className='ml-[8px]'
                sx={{ color: 'var(--mui-palette-text-primary) !important' }}
              >
                {BackupStatuses[props.entity.backupStatus].title}
              </Typography>
            </Stack>
            <Typography>
              {BackupStatuses[props.entity.backupStatus].description}
            </Typography>

            {props.entity.backupStatus ===
              InventoryResourceBackupStatusEnum.ViolationsDetected && (
              <BackupStatusContent resourceId={props.entity.id!} />
            )}
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion disableGutters defaultExpanded={props.openSections.Properties}>
        <AccordionSummary>{PropertyGroups.primary.title}</AccordionSummary>
        <AccordionDetails>
          <SidePanelPropsTable>
            <tbody>
              {props.fields
                .filter(
                  (x) =>
                    x.id !== resourceName.name &&
                    x.id !== resourceId.name &&
                    x.id !== resourceType.name
                )
                .map((x) => ({
                  id: x.id,
                  title: x.title,
                  group: x.group,
                  value: x.value(props.entity),
                }))
                .filter((x) => x.group === 'primary' && x.value)
                .map((x) => (
                  <tr key={x.id}>
                    <td className='props-col'>{x.title(props.entity)}</td>
                    <td>{x.value}</td>
                  </tr>
                ))}
            </tbody>
          </SidePanelPropsTable>
        </AccordionDetails>
      </Accordion>
      <Accordion
        disableGutters
        defaultExpanded={props.openSections.BackupDetailsFields}
      >
        <AccordionSummary>
          {PropertyGroups['backup-details'].title}
        </AccordionSummary>
        <AccordionDetails>
          <SidePanelPropsTable>
            <tbody>
              {props.fields
                .map((x) => ({
                  id: x.id,
                  title: x.title,
                  group: x.group,
                  value: x.value(props.entity),
                }))
                .filter((x) => x.group === 'backup-details' && x.value)
                .map((x) => (
                  <tr key={x.id}>
                    <td className='props-col'>{x.title(props.entity)}</td>
                    <td>{x.value}</td>
                  </tr>
                ))}
            </tbody>
          </SidePanelPropsTable>
        </AccordionDetails>
      </Accordion>
      <Accordion
        disableGutters
        defaultExpanded={props.openSections.SourceLocationFields}
      >
        <AccordionSummary>
          {PropertyGroups['source-location'].title}
        </AccordionSummary>
        <AccordionDetails>
          <SidePanelPropsTable>
            <tbody>
              {props.fields
                .map((x) => ({
                  id: x.id,
                  title: x.title,
                  group: x.group,
                  value: x.value(props.entity),
                }))
                .filter((x) => x.group === 'source-location' && x.value)
                .map((x) => (
                  <tr key={x.id}>
                    <td className='props-col'>{x.title(props.entity)}</td>
                    <td>{x.value}</td>
                  </tr>
                ))}
            </tbody>
          </SidePanelPropsTable>
        </AccordionDetails>
      </Accordion>
      {props.entity.path && (
        <Accordion disableGutters defaultExpanded={true}>
          <AccordionSummary>File Explorer</AccordionSummary>
          <AccordionDetails>
            <SingleLevelFileExplorer
              resourceId={props.resourceId}
              path={getParentPath(props.entity.path)}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};
