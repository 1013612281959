import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import type { Column } from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import { useWorkspace } from '@/contexts/useWorkspace';
import {
  getCombineFilter,
  isValueCondition,
  type CombineCondition,
  type Condition,
  type FilterProperty,
} from '@/types/advanceFilter';

import { FiltersPanelWrapper } from './filtersPanelWrapper';

import { getProperties } from '../queryBuilder/propertiesUtils';
import { PropertyGroups } from '../queryBuilder/propertyGroups';

export const FiltersPanel = <T,>({
  columns,
  columnFilters,
  onChange,
  expandedPropertyName,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Column<any>[];
  columnFilters: Condition | undefined;
  onChange: (filter: Condition) => void;
  expandedPropertyName?: string;
}) => {
  const { leftPanel } = useWorkspace();
  const [filter, setFilter] = useState<CombineCondition>(
    getCombineFilter(columnFilters)
  );

  useEffect(() => {
    setFilter(getCombineFilter(columnFilters));
  }, [columnFilters]);

  const groupedColumns = columns
    .filter((x) => x.getCanFilter())
    .reduce<Record<string, Column<T>[]>>((acc, column) => {
      getProperties(column).forEach(({ group }) => {
        if (group) {
          acc[group] = acc[group] || [];
          acc[group].push(column);
        }
      });
      return acc;
    }, {});

  const renderFiltersPanel = (
    columnFilters: Condition | undefined,
    expandedPropertyName?: string
  ) => {
    leftPanel.setComponent({
      panel: 'Filters',
      props: {
        columns,
        columnFilters,
        onChange,
        expandedPropertyName,
      },
    });
  };
  return (
    <FiltersPanelWrapper
      title='Filter Resources'
      subtitle='Use filters to explore resources and manage your backups'
    >
      {Object.entries(groupedColumns).map(([group, columnsList]) => {
        return (
          <Box key={group}>
            <Typography
              variant='subtitle1'
              className='px-[24px] py-[8px] my-[12px]'
              sx={{
                backgroundColor: 'var(--mui-palette-background-tableHeader)',
                textTransform: 'uppercase',
              }}
            >
              {PropertyGroups[group].title}
            </Typography>
            {columnsList.map((column) => {
              const properties = getProperties(column);
              const propertyWithComponent = properties.find(
                (property) => property?.filter?.Component
              );
              const openFullSize = propertyWithComponent?.filter?.openFullSize;

              return (
                <Accordion
                  disableGutters
                  key={column.id}
                  sx={{
                    backgroundColor: 'transparent',
                  }}
                  defaultExpanded={expandedPropertyName === column.id}
                >
                  <AccordionSummary
                    data-testid={`filter-accordion-${column.id}`}
                    className='px-[24px] py-[12px] font-normal'
                    expandIcon={
                      <i className='material-symbols-arrow-right-rounded' />
                    }
                    sx={{
                      backgroundColor: 'transparent',
                      '& .MuiAccordionSummary-root': {
                        backgroundColor: 'transparent',
                      },
                      '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                        transform: 'rotate(90deg)',
                      },
                    }}
                    onClick={
                      openFullSize &&
                      (() =>
                        leftPanel.setComponent({
                          panel: 'FiltersDrillDown',
                          props: {
                            columnFilters,
                            title: openFullSize.title,
                            subtitle: openFullSize.subtitle,
                            Component: ({ filter }) => (
                              <FilterComponent
                                property={propertyWithComponent}
                                renderFiltersPanel={renderFiltersPanel}
                                filter={filter}
                                onChange={(newFilter) => {
                                  setFilter(newFilter);
                                  onChange(newFilter);
                                }}
                              />
                            ),
                            onBack: (columnFilters) =>
                              renderFiltersPanel(columnFilters),
                          },
                        }))
                    }
                  >
                    <Stack
                      className='w-full'
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <span>{`${column.columnDef.header}`}</span>
                      {filter.conditions.some(
                        (x) =>
                          isValueCondition(x) &&
                          properties.some(
                            (property) => x.property === property?.name
                          )
                      ) && (
                        <Box
                          className='rounded inline-block mr-[16px] h-[8px] w-[8px]'
                          sx={{
                            backgroundColor: 'currentColor',
                          }}
                        />
                      )}
                    </Stack>
                  </AccordionSummary>
                  {!openFullSize && (
                    <AccordionDetails className='py-0 px-[24px]'>
                      <FilterComponent
                        property={propertyWithComponent}
                        filter={filter}
                        renderFiltersPanel={renderFiltersPanel}
                        onChange={(newFilter) => {
                          setFilter(newFilter);
                          onChange(newFilter);
                        }}
                      />
                    </AccordionDetails>
                  )}
                </Accordion>
              );
            })}
          </Box>
        );
      })}
    </FiltersPanelWrapper>
  );
};

function FilterComponent({
  filter,
  property,
  onChange,
  renderFiltersPanel,
}: {
  filter: CombineCondition;
  property: FilterProperty | undefined;
  onChange: (filter: CombineCondition) => void;
  renderFiltersPanel: (
    columnFilters: Condition | undefined,
    expandedPropertyName?: string
  ) => void;
}) {
  if (!property) {
    return null;
  }

  const Component = property.filter?.Component;

  return (
    Component && (
      <Component
        property={property}
        conditions={filter.conditions}
        data-testid={`filter-${property.name}`}
        renderFiltersPanel={renderFiltersPanel}
        onChange={(conditions) => {
          const newFilter = {
            ...filter,
            conditions,
          };

          onChange(newFilter);
        }}
      />
    )
  );
}
