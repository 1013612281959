import type { InventoryResource } from '@repo/api-gw-sdk';
import type { PaginationState, SortingState } from '@tanstack/react-table';
import { useState } from 'react';

import {
  accountId,
  backupRegions,
  genericSnapshotsCount,
  id,
  lastBackup,
  resourceId,
  sourceRegion,
  sourceStorageSize,
  subnets,
  tags,
  vaults,
  vpc,
} from '@/components/queryBuilder/properties';
import { Table } from '@/components/table';
import { useColumns } from '@/components/table/hooks';
import { useDAL } from '@/data/dal';
import { createCombineCondition, type Condition } from '@/types/advanceFilter';

import { getColumns } from './inventoryListTableColumns';

interface InventoryListTableProps {
  openFilters?: boolean;
  hideHeader?: boolean;
  showFiltersBar?: boolean;
  sorting: SortingState;
  selectedId?: string;
  pagination: PaginationState;
  columnFilters?: Condition;
  baseFilter?: Condition;
  allowedColumns?: string[];
  disableSelection?: boolean;
  onSortingChange: (sortField: string, desc: boolean) => void;
  onPaginationChange: (value: PaginationState) => void;
  onColumnFiltersChange: (filter: Condition) => void;
  onRowSelectionChange?: (entities: InventoryResource[]) => void;
}

export const InventoryListTable = (props: InventoryListTableProps) => {
  const dal = useDAL();
  let filter = props.columnFilters || props.baseFilter;
  if (props.baseFilter && props.columnFilters) {
    filter = createCombineCondition([props.baseFilter, props.columnFilters]);
  }

  const { body, isLoading } = dal.inventory.get(
    props.pagination,
    props.sorting[0],
    filter
  );

  const columns = useColumns(getColumns);
  const allowsColumns = props.allowedColumns?.length
    ? columns.filter((column) =>
        props.allowedColumns?.includes(column.accessorKey || '')
      )
    : columns;

  return (
    <Table
      disableSelection={props.disableSelection}
      openFilters={props.openFilters}
      showFiltersBar={props.showFiltersBar}
      data={body?.resources || []}
      isMultiSelect={true}
      columns={allowsColumns}
      columnFilters={props.columnFilters}
      hiddenColumns={[
        id.name,
        tags.name,
        accountId.name,
        resourceId.name,
        vpc.name,
        subnets.name,
        lastBackup.name,
        sourceRegion.name,
        backupRegions.name,
        sourceStorageSize.name,
        vaults.name,
        genericSnapshotsCount.name,
      ]}
      sorting={props.sorting}
      isLoading={isLoading}
      onColumnFiltersChange={props.onColumnFiltersChange}
      onSortingChanged={(state) =>
        props.onSortingChange(state[0].id, state[0].desc)
      }
      onRowSelectionChange={props.onRowSelectionChange}
      pagination={{
        ...props.pagination,
        recordsCount: body?.pagination.recordsCount,
        onChange: props.onPaginationChange,
      }}
      columnSelection={!props.hideHeader}
      selectedId={props.selectedId}
    />
  );
};

interface InventoryListProps {
  openFilters?: boolean;
  hideHeader?: boolean;
  showFiltersBar?: boolean;
  sorting?: SortingState;
  selectedId?: string;
  pagination?: PaginationState;
  columnFilters?: Condition;
  baseFilter?: Condition;
  allowedColumns?: string[];
  disableSelection?: boolean;
  onSortingChange?: (sortField: string, desc: boolean) => void;
  onPaginationChange?: (value: PaginationState) => void;
  onColumnFiltersChange?: (filter: Condition) => void;
  onRowSelectionChange?: (entities: InventoryResource[]) => void;
}

export const InventoryList = (props: InventoryListProps) => {
  const [sorting, setSorting] = useState<SortingState>(
    props.sorting || [{ id: 'id', desc: false }]
  );
  const [paging, setPaging] = useState<PaginationState>(
    props.pagination || { pageIndex: 0, pageSize: 25 }
  );
  const [columnFilters, setColumnFilters] = useState<Condition | undefined>(
    props.columnFilters
  );

  return (
    <InventoryListTable
      {...props}
      sorting={sorting}
      pagination={paging}
      columnFilters={columnFilters}
      onColumnFiltersChange={(filter: Condition) => {
        setColumnFilters(filter);
        if (props.onColumnFiltersChange) {
          props.onColumnFiltersChange(filter);
        }
      }}
      onPaginationChange={(value: PaginationState) => {
        setPaging(value);
        if (props.onPaginationChange) {
          props.onPaginationChange(value);
        }
      }}
      onSortingChange={(sortField: string, desc: boolean) => {
        setSorting([{ id: sortField, desc }]);
        if (props.onSortingChange) {
          props.onSortingChange(sortField, desc);
        }
      }}
    />
  );
};
