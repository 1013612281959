import {
  Box,
  Button,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { EncryptionComponent } from '@/components/encryption/encryptionComponent';
import { AvailabilityZoneSelect } from '@/components/regions/availabilityZoneSelect';
import { Icon } from '@/components/shared/icon';
import { TransactionalTextField } from '@/components/shared/transactionalTextField';
import { VolumeSettingsSection } from '@/components/volumes/volumeSettings';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';

import { StepContainer } from '../../wizard/StepContainer';
import { type RestoreVolumeState } from '../restoreVolumeWizard';

export const SnapshotConfiguration = (props: StepProps<RestoreVolumeState>) => {
  const dal = useDAL();
  const [markWithEonTag, setMarkWithEonTag] = useState(true);
  const { body: regionsResponse } = dal.restore.regions.getForAccount(
    props.state.restoreAccountId!
  );
  const { body: restoreInfoResponse } = dal.inventory.volumes.get(
    props.state.volumeResourceId,
    props.state.resourceIdForSnapshots
  );
  useEffect(() => {
    if (restoreInfoResponse && !props.state.tags) {
      props.setState((state) => ({
        ...state,
        tags: restoreInfoResponse.tags,
        volumeSettings: restoreInfoResponse.volumeSettings,
        regionName: restoreInfoResponse.regionName,
        availabilityZone: restoreInfoResponse.availabilityZone,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restoreInfoResponse]);

  return (
    <StepContainer
      stepperLabels={props.stepperLabels}
      stepperIndex={props.stepperLabels.length - 1}
      nextButtonText='Start'
      onBackClick={props.back}
      canGoNext={() => !!props.state.regionName}
      onNextClick={() => {
        const tags = props.state.tags || {};
        if (markWithEonTag) {
          tags['eon:restore'] = 'true';
        }

        if (props.state.actionType === 'snapshot') {
          void dal.convert
            .volume(
              props.state.resourceIdForSnapshots,
              props.state.volumeResourceId,
              {
                tags,
                regionName: props.state.regionName ?? '',
                snapshotId: props.state.snapshotId!,
                restoreAccountId: props.state.restoreAccountId!,
                encryptionKeyId: props.state.encryptionKeyId,
                description: props.state.description,
              }
            )

            .then(() => {
              props.abort();
            });
        } else if (props.state.actionType === 'volume') {
          void dal.restore
            .volume(
              props.state.resourceIdForSnapshots,
              props.state.volumeResourceId,
              {
                tags,
                regionName: props.state.regionName ?? '',
                snapshotId: props.state.snapshotId!,
                restoreAccountId: props.state.restoreAccountId!,
                encryptionKeyId: props.state.encryptionKeyId,
                description: props.state.description,
                availabilityZone: props.state.availabilityZone!,
                volumeSettings: props.state.volumeSettings!,
              }
            )
            .then(() => {
              props.abort();
            });
        }
      }}
    >
      <Box>
        <Typography variant='h5'>Configuration</Typography>
        <Box className='w-full flex justify-between mt-[20px] gap-[36px]'>
          <FormControl size='small' className='flex-1'>
            <FormLabel>Region</FormLabel>
            <Select
              size='small'
              value={(regionsResponse?.regions && props.state.regionName) || ''}
              onChange={(event) =>
                props.setState((state) => ({
                  ...state,
                  regionName: event.target.value,
                }))
              }
            >
              {(regionsResponse?.regions || []).map((region) => (
                <MenuItem key={region} value={region}>
                  {region}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {props.state.actionType === 'volume' && (
            <AvailabilityZoneSelect
              accountId={props.state.restoreAccountId}
              regionName={props.state.regionName}
              availabilityZone={props.state.availabilityZone}
              onChange={(availabilityZone) =>
                props.setState((state) => ({
                  ...state,
                  availabilityZone,
                }))
              }
            />
          )}
        </Box>
        <FormControl size='small' className='w-full mt-[24px]'>
          <FormLabel>Description (optional)</FormLabel>
          <TextField
            size='small'
            value={props.state.description || ''}
            onChange={(event) =>
              props.setState((state) => ({
                ...state,
                description: event.target.value,
              }))
            }
          />
        </FormControl>
        <Box className='flex items-center my-[24px] ml-[4px]'>
          <FormControlLabel
            control={
              <Checkbox
                checked={markWithEonTag}
                onChange={() => setMarkWithEonTag(!markWithEonTag)}
              />
            }
            label={
              <Typography className='ml-[12px]'>{`Mark with Eon's restoration tag`}</Typography>
            }
          />
          <Tooltip
            title={
              <>
                Adding Eon restoration tag will help in identifying and
                searching for the resource
                <br />
                <br />
                Key: eon:restore
                <br />
                Value: true
              </>
            }
          >
            <Icon iconClass='material-symbols-info-outline' />
          </Tooltip>
        </Box>
      </Box>
      {props.state.actionType === 'volume' && (
        <>
          <Divider />
          <CardContent className='px-0'>
            <VolumeSettingsSection
              initialSettings={restoreInfoResponse?.volumeSettings}
              settings={props.state.volumeSettings}
              onChange={(volumeSettings) =>
                props.setState((state) => ({ ...state, volumeSettings }))
              }
            />
          </CardContent>
        </>
      )}
      <Divider />
      <CardContent className='px-0'>
        <Tags
          initialTags={restoreInfoResponse?.tags || {}}
          tags={props.state.tags || {}}
          onChange={(tags) => props.setState((state) => ({ ...state, tags }))}
        />
      </CardContent>
      <Divider />
      <CardContent className='px-0'>
        <EncryptionComponent
          encryptionKeyId={restoreInfoResponse?.encryptionKeyId}
          accountId={props.state.restoreAccountId!}
          regionName={props.state.regionName}
          onChange={(volumeEncryption) => {
            if (volumeEncryption.isEncrypted) {
              props.setState((state) => ({
                ...state,
                encryptionKeyId:
                  volumeEncryption.arn || volumeEncryption.encryptionKeyId,
              }));
            } else {
              props.setState((state) => ({
                ...state,
                encryptionKeyId: undefined,
              }));
            }
          }}
        />
      </CardContent>
    </StepContainer>
  );
};

const Tags = ({
  initialTags,
  tags,
  onChange,
}: {
  tags: Record<string, string>;
  initialTags: Record<string, string>;
  onChange: (tags: Record<string, string>) => void;
}) => {
  const [keepOriginalTags, setKeepOriginalTags] = useState<boolean>(true);

  return (
    <Box className='w-full'>
      <Box className='flex justify-between items-center'>
        <Typography component={'span'} variant='h6'>
          Keep Original Tags
        </Typography>
        <Switch
          size='small'
          checked={keepOriginalTags}
          onChange={() => {
            setKeepOriginalTags(!keepOriginalTags);
            if (!keepOriginalTags) {
              onChange(initialTags);
            }
          }}
        />
      </Box>
      {!keepOriginalTags && (
        <Box className='mt-[20px] w-1/2'>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell className='border-0 pl-0'>Key</TableCell>
                <TableCell className='border-0 pl-0'>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(tags).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell className='border-0 pl-0'>
                    <TransactionalTextField
                      initValue={key}
                      onChange={(newKey) => {
                        if (key !== newKey) {
                          const newState = {
                            ...tags,
                            [newKey]: tags[key],
                          };

                          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                          delete newState[key];
                          onChange(newState);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell className='border-0 pl-0'>
                    <TextField
                      tabIndex={2}
                      size='small'
                      value={value}
                      onChange={(event) => {
                        const newState = {
                          ...tags,
                          [key]: event.target.value,
                        };
                        onChange(newState);
                      }}
                    />
                  </TableCell>
                  <TableCell className='border-0 pl-0'>
                    <i
                      className={
                        'material-symbols-close-rounded cursor-pointer table-cell'
                      }
                      onClick={() => {
                        const newState = {
                          ...tags,
                        };

                        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                        delete newState[key];
                        onChange(newState);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            className='mt-[20px]'
            size={'small'}
            variant='outlined'
            onClick={() => onChange({ ...tags, '': '' })}
          >
            + Add Tag
          </Button>
        </Box>
      )}
    </Box>
  );
};
