import { styled } from '@mui/material';
import type { CSSObject } from '@mui/material';

import { menuButtonStyles } from '../../components/vertical-menu/MenuButton';
import type { MenuItemProps } from '../../components/vertical-menu/MenuItem';
import { menuClasses } from '../../utils/menuClasses';

type StyledVerticalMenuItemProps = Pick<
  MenuItemProps,
  'rootStyles' | 'disabled'
> & {
  level: number;
  menuItemStyles?: CSSObject;
  isCollapsed?: boolean;
  isPopoutWhenCollapsed?: boolean;
  buttonStyles?: CSSObject;
};

const StyledVerticalMenuItem = styled('li')<StyledVerticalMenuItemProps>`
  position: relative;
  height: 59px;

  ${({ menuItemStyles }) => menuItemStyles};
  ${({ rootStyles }) => rootStyles};

  > .${menuClasses.button} {
    ${({ level, disabled, isCollapsed, isPopoutWhenCollapsed }) =>
      menuButtonStyles({
        level,
        disabled,
        isCollapsed,
        isPopoutWhenCollapsed,
      })};
    ${({ buttonStyles }) => buttonStyles};
  }
`;

export default StyledVerticalMenuItem;
