import {
  apps,
  accountId,
  environment,
  lastBackup,
  path,
  resourceId,
  resourceName,
  resourceType,
  subnets,
  tags,
  vpc,
} from '@/components/queryBuilder/properties';

export const fileSearchFields = [
  resourceId,
  accountId,
  resourceName,
  resourceType,
  path,
  apps,
  environment,
  tags,
  vpc,
  subnets,
  lastBackup,
];
