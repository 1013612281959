import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import classnames from 'classnames';
import { useState } from 'react';

import { EncryptionComponent } from '@/components/encryption/encryptionComponent';
import { TransactionalTextField } from '@/components/shared/transactionalTextField';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';

import { StepContainer } from '../../wizard/StepContainer';
import type { RestoreFileState } from '../restoreFileWizard';

export const FileConfiguration = (props: StepProps<RestoreFileState>) => {
  const dal = useDAL();
  const { body: regionsResponse } = dal.restore.regions.getForAccount(
    props.state.restoreAccountId!
  );

  const { body: bucketsResponse } = dal.restore.buckets.get(
    props.state.restoreAccountId!
  );

  const [mode, setMode] = useState('select');

  return (
    <StepContainer
      stepperLabels={props.stepperLabels}
      stepperIndex={props.stepperLabels.length - 1}
      nextButtonText='Start'
      onBackClick={props.back}
      canGoNext={() => {
        if (mode === 'create') {
          return (
            !!props.state.restoreBucketName && !!props.state.restoreBucketRegion
          );
        }

        return !!props.state.restoreBucketArn;
      }}
      onNextClick={() => {
        void dal.restore
          .files(props.state.resourceIdForSnapshots!, props.state.snapshotId!, {
            files: props.state.files,
            restoreAccountId: props.state.restoreAccountId!,
            restoreBucketPrefix: props.state.restoreBucketPrefix,
            encryptionKeyId: props.state.encryptionKeyId,
            restoreBucketArn:
              mode === 'create' ? undefined : props.state.restoreBucketArn,
            restoreBucketName:
              mode === 'create' ? props.state.restoreBucketName : undefined,
            restoreBucketRegion:
              mode === 'create' ? props.state.restoreBucketRegion : undefined,
          })
          .then(() => {
            props.abort();
          });
      }}
    >
      <Box>
        <Typography variant='h5'>Configuration</Typography>
        <List className='mt-[20px]'>
          <OptionListItem onClick={() => setMode('select')}>
            <FormControlLabel
              checked={mode === 'select'}
              onClick={() => setMode('select')}
              control={<Radio />}
              label='Select an Existing S3 Bucket'
            />
            <Box
              className={classnames('w-1/2 content', {
                expanded: mode === 'select',
              })}
            >
              <FormControl size='small' className='w-full'>
                <FormLabel>Bucket</FormLabel>
                <Select
                  size='small'
                  value={
                    (bucketsResponse?.data && props.state.restoreBucketArn) ||
                    ''
                  }
                  onChange={(event) =>
                    props.setState((state) => ({
                      ...state,
                      restoreBucketArn: event.target.value,
                    }))
                  }
                >
                  {(bucketsResponse?.data || []).map((bucket) => (
                    <MenuItem key={bucket.arn} value={bucket.arn}>
                      {bucket.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </OptionListItem>
          <OptionListItem onClick={() => setMode('arn')}>
            <FormControlLabel
              checked={mode === 'arn'}
              onClick={() => setMode('arn')}
              control={<Radio />}
              label='Enter a Bucket ARN'
            />
            <Box
              className={classnames('w-1/2 content', {
                expanded: mode === 'arn',
              })}
            >
              <FormControl size='small' className='w-full'>
                <FormLabel>Bucket Arn</FormLabel>
                <TextField
                  size='small'
                  value={props.state.restoreBucketArn || ''}
                  onChange={(event) =>
                    props.setState((state) => ({
                      ...state,
                      restoreBucketArn: event.target.value,
                    }))
                  }
                />
              </FormControl>
            </Box>
          </OptionListItem>
          <OptionListItem onClick={() => setMode('create')}>
            <FormControlLabel
              checked={mode === 'create'}
              onClick={() => setMode('create')}
              control={<Radio />}
              label='Create a New Bucket'
            />
            <Box
              className={classnames('w-full content', {
                expanded: mode === 'create',
              })}
            >
              <Box className='flex gap-[36px] mb-[20px]'>
                <FormControl size='small' className='w-1/2'>
                  <FormLabel>Bucket Name</FormLabel>
                  <TransactionalTextField
                    initValue={props.state.restoreBucketName || ''}
                    onChange={(value) => {
                      props.setState((state) => ({
                        ...state,
                        restoreBucketName: value,
                      }));
                    }}
                  />
                </FormControl>
                <FormControl size='small' className='w-1/2'>
                  <FormLabel>Region</FormLabel>
                  <Select
                    size='small'
                    value={
                      (regionsResponse?.regions &&
                        props.state.restoreBucketRegion) ||
                      ''
                    }
                    onChange={(event) =>
                      props.setState((state) => ({
                        ...state,
                        restoreBucketRegion: event.target.value,
                      }))
                    }
                  >
                    {(regionsResponse?.regions || []).map((region) => (
                      <MenuItem key={region} value={region}>
                        {region}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <EncryptionComponent
                encryptionKeyId={undefined}
                accountId={props.state.restoreAccountId!}
                regionName={props.state.restoreBucketRegion ?? ''}
                onChange={(params) =>
                  props.setState((state) => ({
                    ...state,
                    encryptionKeyId: params.isEncrypted
                      ? params.arn || params.encryptionKeyId
                      : undefined,
                  }))
                }
              />
            </Box>
          </OptionListItem>
        </List>
        <FormControl size='small' className='w-full mt-[24px]'>
          <FormLabel>Target Path (optional)</FormLabel>
          <TextField
            size='small'
            value={props.state.restoreBucketPrefix || ''}
            onChange={(event) =>
              props.setState((state) => ({
                ...state,
                restoreBucketPrefix: event.target.value,
              }))
            }
          />
        </FormControl>
      </Box>
    </StepContainer>
  );
};

const OptionListItem = styled(ListItem)({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  alignItems: 'flex-start',
  padding: '16px',
  borderRadius: 'var(--mui-shape-borderRadius)',
  border: '2px solid var(--mui-palette-primary-main)',
  marginBottom: '8px',
  gap: 0,

  '.content': {
    height: 0,
    overflow: 'hidden',

    '> :first-child': {
      marginTop: '20px',
    },

    '&.expanded': {
      height: 'auto',
      cursor: 'default',
    },
  },
});
