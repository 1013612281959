import { Stack } from '@mui/material';
import type { FileExplorerResult } from '@repo/api-gw-sdk';
import Link from 'next/link';
import { useState } from 'react';

import {
  getParentPath,
  identifyDelimiter,
} from '@/app/(dashboard)/search/searchUtils';
import { useDAL } from '@/data/dal';

export interface SingleLevelFileExplorerProps {
  resourceId: string;
  path: string;
  onDirectorySelected?: (path: string) => void;
  onFileSelected?: (item: FileExplorerResult) => void;
}

export function SingleLevelFileExplorer(props: SingleLevelFileExplorerProps) {
  const dal = useDAL();
  const [currPath, setCurrPath] = useState<string>(props.path || '');
  const delimiter = identifyDelimiter(currPath);
  const [depth, setDepth] = useState(currPath.split(delimiter).length + 1);

  const { body: dirs, isLoading: isDirsLoading } = dal.explorer.getDirectories(
    props.resourceId,
    currPath,
    depth,
    {
      pageIndex: 0,
      pageSize: 50,
    }
  );

  const { body: files, isLoading: isFilesLoading } = dal.explorer.getFiles(
    props.resourceId,
    currPath || '',
    depth,
    {
      pageIndex: 0,
      pageSize: 50,
    }
  );

  const onItemClicked = (item: FileExplorerResult) => {
    if (isFilesLoading || isDirsLoading) return;
    if (item.type === 'f' && props.onFileSelected) {
      props.onFileSelected(item);
    } else if (item.type === 'd' && props.onDirectorySelected) {
      props.onDirectorySelected(item.path);
    }

    if (item.type === 'd' && item.path) {
      setCurrPath(item.path);
      setDepth(depth + 1);
    }
  };

  const levelUp = () => {
    const parent = getParentPath(currPath);
    setCurrPath(parent);
    setDepth(depth - 1);
    if (props.onDirectorySelected) {
      props.onDirectorySelected(parent);
    }
  };

  return (
    <>
      <Stack style={{ opacity: isDirsLoading || isFilesLoading ? '0.6' : 1 }}>
        {depth > 1 && (
          <div className='cursor-pointer' onClick={() => levelUp()}>
            <i className='bi-arrow-90deg-up text-[14px] text-primary align-middle'></i>
            <span className='ml-[5px] text-[16px]'>...</span>
          </div>
        )}

        {dirs?.data?.map((item, i) => (
          <div
            key={i}
            onClick={() => onItemClicked(item)}
            className='cursor-pointer'
          >
            <i className='bi-folder2 text-[14px] text-primary align-middle' />
            <span className='ml-[5px] text-[16px]'>
              {item.path?.split(delimiter).pop()}
            </span>
          </div>
        ))}

        {files?.data?.map((item, i) => (
          <div
            key={i}
            onClick={() => onItemClicked(item)}
            className='cursor-pointer'
          >
            <i className='bi-file-earmark text-[14px] text-primary align-middle' />
            <span className='ml-[5px] text-[16px]'>
              {item.path?.split(delimiter).pop()}
            </span>
          </div>
        ))}
      </Stack>
      <div className='mt-[15px]'>
        <Link
          prefetch={false}
          href={`/explorer?resourceId=${props.resourceId}&path=${currPath}`}
          style={{ color: 'var(--primary-color)' }}
        >
          <i className='mingcute-directory-line text-[22px] text-textSecondary align-middle' />
          Reveal in Resource Explorer
        </Link>
      </div>
    </>
  );
}
