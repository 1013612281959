import type { ComponentProps } from 'react';

import { InventoryInstancePanel } from '@/app/(dashboard)/inventory/[[...ids]]/inventoryInstancePanel';
import { FileSearchInstancePanel } from '@/app/(dashboard)/search/fileSearchInstancePanel';
import BackupPolicyPanel from '@/app/(dashboard)/settings/backup-policies/BackupPolicySidePanel';
import { CreateAccountPanel } from '@/app/(dashboard)/settings/cloud-accounts/[type]/createAccountPanel';
import ControlWizard from '@/app/(dashboard)/settings/controls/ControlWizard';

import type { Panels } from './panels';

import { FiltersDrillDownPanel } from '../filters/filtersDrillDownPanel';
import { FiltersPanel } from '../filters/filtersPanel';
import { GroupPanel } from '../groups/groupPanel';
import { ProjectPanel } from '../projects/projectPanel';
import { RestoreFileWizard } from '../restore/restoreFileWizard';
import { RestoreVolumeWizard } from '../restore/restoreVolumeWizard';
import { SamlPanel } from '../saml/samlPanel';
import { UserPanel } from '../users/userPanel';
import { EditBackupVaultPanel } from '../vaults/EditBackupVaultPanel';

type PanelsMap<T extends PanelTypes> = {
  [k in T['panel']]: (
    props: Extract<PanelTypes, { panel: k }>['props']
  ) => React.ReactNode;
};

export const PanelsMap: PanelsMap<PanelTypes> = {
  InventoryInstance: InventoryInstancePanel,
  FileSearchInstance: FileSearchInstancePanel,
  RestoreVolumeWizard: RestoreVolumeWizard,
  RestoreFileWizard: RestoreFileWizard,
  EditBackupVault: EditBackupVaultPanel,
  BackupPolicy: BackupPolicyPanel,
  Control: ControlWizard,
  User: UserPanel,
  Group: GroupPanel,
  SAML: SamlPanel,
  Project: ProjectPanel,
  Filters: FiltersPanel,
  FiltersDrillDown: FiltersDrillDownPanel,
  CloudAccount: CreateAccountPanel,
};

export type PanelTypes =
  | {
      panel: typeof Panels.InventoryInstance;
      props: ComponentProps<typeof InventoryInstancePanel>;
    }
  | {
      panel: typeof Panels.FileSearchInstance;
      props: ComponentProps<typeof FileSearchInstancePanel>;
    }
  | {
      panel: typeof Panels.EditBackupVault;
      props: ComponentProps<typeof EditBackupVaultPanel>;
    }
  | {
      panel: typeof Panels.RestoreVolumeWizard;
      props: ComponentProps<typeof RestoreVolumeWizard>;
    }
  | {
      panel: typeof Panels.RestoreFileWizard;
      props: ComponentProps<typeof RestoreFileWizard>;
    }
  | {
      panel: typeof Panels.User;
      props: ComponentProps<typeof UserPanel>;
    }
  | {
      panel: typeof Panels.Group;
      props: ComponentProps<typeof GroupPanel>;
    }
  | {
      panel: typeof Panels.SAML;
      props: ComponentProps<typeof SamlPanel>;
    }
  | {
      panel: typeof Panels.Project;
      props: ComponentProps<typeof ProjectPanel>;
    }
  | {
      panel: typeof Panels.Filters;
      props: ComponentProps<typeof FiltersPanel>;
    }
  | {
      panel: typeof Panels.FiltersDrillDown;
      props: ComponentProps<typeof FiltersDrillDownPanel>;
    }
  | {
      panel: typeof Panels.BackupPolicy;
      props: ComponentProps<typeof BackupPolicyPanel>;
    }
  | {
      panel: typeof Panels.Control;
      props: ComponentProps<typeof ControlWizard>;
    }
  | {
      panel: typeof Panels.CloudAccount;
      props: ComponentProps<typeof CreateAccountPanel>;
    };
