import {
  MenuItem,
  Select,
  Stack,
  styled,
  Typography,
  type SelectChangeEvent,
} from '@mui/material';
import type { BackupVault, BackupSchedule } from '@repo/api-gw-sdk';
import { useEffect, useState } from 'react';

import OptionMenu from '@/@core/components/option-menu';
import type {
  OptionMenuItemType,
  OptionType,
} from '@/@core/components/option-menu/types';
import { BackupVaultSelectionDialog } from '@/components/vaults/BackupVaultSelectionDialog';
import VaultTag from '@/components/vaults/VaultTag';
import useBackupVaults from '@/data/vaults/useBackupVaults';

const retentionMapping: {
  duration: string;
  text: string;
  retentions: { value: number; text: string }[];
}[] = [
  {
    duration: '0 0 * * *',
    text: 'Daily backups',
    retentions: [
      {
        value: 7,
        text: '1 week',
      },
      {
        value: 14,
        text: '2 weeks',
      },
      {
        value: 30,
        text: '1 month',
      },
      {
        value: 90,
        text: '3 months',
      },
      {
        value: 180,
        text: '6 months',
      },
      {
        value: 365,
        text: '1 year',
      },
    ],
  },
  {
    duration: '0 0 * * 0',
    text: 'Weekly backups',
    retentions: [
      {
        value: 30,
        text: '1 month',
      },
      {
        value: 90,
        text: '3 months',
      },
      {
        value: 180,
        text: '6 months',
      },
      {
        value: 365,
        text: '1 year',
      },
    ],
  },
  {
    duration: '0 0 1 * *',
    text: 'Monthly backups',
    retentions: [
      {
        value: 90,
        text: '3 months',
      },
      {
        value: 180,
        text: '6 months',
      },
      {
        value: 365,
        text: '1 year',
      },
      {
        value: 730,
        text: '2 years',
      },
      {
        value: 1095,
        text: '3 years',
      },
    ],
  },
  {
    duration: '0 0 1 1 *',
    text: 'Annual backups',
    retentions: [
      {
        value: 365,
        text: '1 year',
      },
      {
        value: 730,
        text: '2 years',
      },
      {
        value: 1095,
        text: '3 years',
      },
      {
        value: 1825,
        text: '5 years',
      },
      {
        value: 3650,
        text: '10 years',
      },
    ],
  },
];

const ScheduleContainer = styled('div')(() => ({
  margin: '8px 0',
}));

const Schedule = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: '16px',
  display: 'inline-flex',
}));

const ScheduleSelect = styled(Select)(({ theme }) => ({
  '&::before, &::after': {
    border: 'none',
  },
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
  },
  '& div.MuiSelect-select': {
    padding: '5px',
  },
  '& .MuiSelect-select .MuiTypography-root': {
    marginRight: '16px',
  },
  '& i': {
    marginLeft: '8px',
    right: 0,
    border: '1px solid green',
  },
  '& .MuiList-root .MuiMenuItem-root p': {
    fontWeight: '400',
  },
  '& .MuiTypography-root': {
    fontWeight: '600',
    color: theme.palette.mode === 'dark' ? 'white' : '#272742',
  },
}));

export interface BackupPolicyScheduleProps {
  schedule: BackupSchedule;
  deleteAllowed?: boolean;
  onUpdate: (schedule: BackupSchedule) => void;
  onDuplicate: (schedule: BackupSchedule) => void;
  onDelete: (schedule: BackupSchedule) => void;
}

export function BackupPolicySchedule(props: BackupPolicyScheduleProps) {
  const { data: vaults, loading } = useBackupVaults();
  const [diagOpen, setDiagOpen] = useState(false);
  const [vault, setVault] = useState<BackupVault | undefined>(undefined);

  useEffect(() => {
    if (!loading && vaults) {
      setVault(vaults.find((v) => v.id === props.schedule.vaultId));
    }
  }, [vaults, loading, props.schedule.vaultId]);

  const updateWindow = (event: SelectChangeEvent<unknown>) => {
    props.onUpdate({
      ...props.schedule,
      windows: event.target.value as string,
    });
  };

  const updateRetention = (event: SelectChangeEvent<unknown>) => {
    props.onUpdate({
      ...props.schedule,
      backupRetention: event.target.value as number,
    });
  };

  return (
    <ScheduleContainer>
      <Schedule direction='row' alignItems='center' gap='12px'>
        <Typography variant='body1'>Take</Typography>
        <ScheduleSelect
          value={props.schedule.windows}
          onChange={updateWindow}
          variant='standard'
          data-testid='create-backup-policy-duration-select'
        >
          {retentionMapping.map((m) => (
            <MenuItem key={m.duration} value={m.duration}>
              <Typography variant='body1'>{m.text}</Typography>
            </MenuItem>
          ))}
        </ScheduleSelect>
        <Typography variant='body1'>and retain for</Typography>
        <ScheduleSelect
          value={props.schedule.backupRetention}
          onChange={updateRetention}
          variant='standard'
          data-testid='create-backup-policy-frequency-select'
        >
          {retentionMapping
            .find((m) => m.duration === props.schedule.windows)
            ?.retentions.map((r) => (
              <MenuItem key={r.value} value={r.value}>
                <Typography variant='body1'>{r.text}</Typography>
              </MenuItem>
            ))}
        </ScheduleSelect>
        <Typography variant='body1'>on</Typography>
        <ScheduleSelect
          sx={{
            '& .MuiSelect-select:focus': {
              backgroundColor: 'transparent',
            },
          }}
          data-testid='create-backup-policy-vault-select'
          variant='standard'
          value={props.schedule.vaultId || 0}
          open={false}
          native={false}
          onClick={() => {
            setDiagOpen(true);
          }}
        >
          {(!props.schedule.vaultId || !vault) && (
            <MenuItem value={props.schedule.vaultId || 0}>
              <Typography variant='body1'>Choose Vault</Typography>
            </MenuItem>
          )}

          {props.schedule.vaultId && vault && (
            <MenuItem value={props.schedule.vaultId}>
              <VaultTag
                vault={vault}
                sx={{ margin: 0, marginRight: '16px', cursor: 'pointer' }}
              />
            </MenuItem>
          )}
        </ScheduleSelect>
        <OptionMenu
          iconClassName='text-textPrimary'
          icon='material-symbols-more-horiz'
          options={[
            { text: 'Duplicate' },
            {
              text: 'Delete',
              menuItemProps: { disabled: !props.deleteAllowed },
            },
          ]}
          onOptionSelected={(option: OptionType): void => {
            switch ((option as OptionMenuItemType).text) {
              case 'Duplicate':
                props.onDuplicate(props.schedule);
                break;
              case 'Delete':
                props.onDelete(props.schedule);
                break;
            }
          }}
        />
      </Schedule>
      {diagOpen && (
        <BackupVaultSelectionDialog
          onClose={() => setDiagOpen(false)}
          onSelect={(vault) => {
            props.onUpdate({ ...props.schedule, vaultId: vault.id });
            setDiagOpen(false);
          }}
        />
      )}
    </ScheduleContainer>
  );
}
