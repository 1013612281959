import Link from 'next/link';
import type { LinkProps } from 'next/link';
import { forwardRef, type PropsWithChildren } from 'react';

type RouterLinkProps = PropsWithChildren<
  LinkProps & {
    className?: string;
  }
>;

export const RouterLink = forwardRef(
  (
    props: RouterLinkProps,
    ref: React.LegacyRef<HTMLAnchorElement> | undefined
  ) => {
    // Props
    const { href, className, ...other } = props;

    return (
      <Link ref={ref} href={href} className={className} {...other}>
        {props.children}
      </Link>
    );
  }
);
