import {
  InventoryResourceResourceTypeEnum,
  type InventoryResource,
} from '@repo/api-gw-sdk';

import { InstancePanel } from '@/components/panels/instancePanel';
import { useFields } from '@/components/table/hooks';
import { useDAL } from '@/data/dal';
import useBackupVaults from '@/data/vaults/useBackupVaults';

import { inventoryItemFields } from './inventoryItemFields';

export const InventoryInstancePanel = ({
  id,
  onClose,
}: {
  id: string;
  onClose: () => void;
}) => {
  const dal = useDAL();
  const { isLoading, body } = dal.inventory.getOne(id);
  const fields = useFields(inventoryItemFields);
  const vaults = useBackupVaults();

  const triggerOnDemandBackup = (entity: InventoryResource) => {
    if (vaults.loading) {
      console.error('vaults are still loading');
      return;
    }

    if (!vaults.data) {
      console.error('no vaults found');
      return;
    }

    const vaultId = vaults.data[0].id;

    console.log(
      `triggering on demand backup job to vault: ${vaultId} for 7 days retention`
    );

    alert('Backup rockets launched—data is headed to safety!');

    void dal.jobs.backup.create(entity.id, vaultId, 7);
  };

  return (
    <>
      <InstancePanel
        entity={body}
        resourceId={body?.id || ''}
        onClose={onClose}
        fields={fields}
        actions={
          body?.resourceType === InventoryResourceResourceTypeEnum.Ec2 ||
          body?.resourceType === InventoryResourceResourceTypeEnum.Rds
            ? [
                {
                  title: 'On demand backup',
                  icon: 'mdi-backup-restore',
                  primary: true,
                  execute: (entity) => {
                    triggerOnDemandBackup(entity);
                  },
                },
              ]
            : []
        }
        isLoading={isLoading}
        openSections={{
          Properties: false,
          SourceLocationFields: false,
          BackupDetailsFields: false,
        }}
      />
    </>
  );
};
