import { Typography, Divider, Switch } from '@mui/material';
import { type ControlRules } from '@repo/api-gw-sdk';
import classNames from 'classnames';
import React from 'react';

import { ConditionEditor } from '@/components/conditions/ConditionEditor';
import {
  apps,
  accountId,
  dataClasses,
  environment,
  resourceType,
  sourceRegion,
  subnets,
  vpc,
} from '@/components/queryBuilder/properties';
import { StepContainer } from '@/components/wizard/StepContainer';
import type { StepProps } from '@/components/wizard/StepProps';
import { type FilterProperty } from '@/types/advanceFilter';

import type { ControlEditState } from './ControlCreationFlow';
import { controlRules } from './ControlRules';

export const supportedControlProperties: FilterProperty[][] = [
  [resourceType, dataClasses, environment, apps],
  [accountId, sourceRegion, vpc, subnets],
];

export const ControlConditionEditor = (props: StepProps<ControlEditState>) => {
  const {
    state: { condition, rules },
  } = props;
  const updateState = (state: Partial<ControlEditState>) =>
    props.setState({ ...props.state, ...state });
  const updateRule = (newRules: ControlRules) =>
    updateState({ rules: { ...rules, ...newRules } });

  return (
    <StepContainer
      sx={{
        minHeight: '100%',
        padding: 0,
        '&:last-child': { paddingBottom: 0 },
        display: 'flex',
      }}
      hideStepper={true}
      onBackClick={props.back}
      canGoNext={() => true}
      onNextClick={props.finish}
    >
      <div className='w-1/2 p-[100px]'>
        <Typography variant='body1' sx={{ mb: '24px' }}>
          Resources where:
        </Typography>
        <ConditionEditor
          condition={condition}
          options={{
            supportedProperties: supportedControlProperties,
            allowUsingProperty: (c) =>
              condition?.property !== c.name &&
              !condition?.conditions?.some(
                (condition) => condition.property === c.name
              ),
          }}
          onChange={(condition) => updateState({ condition })}
        />
      </div>
      <Divider orientation='vertical' flexItem />
      <div className='w-1/2'>
        <Typography variant='body1' sx={{ padding: '100px 0 20px 100px' }}>
          Need to follow these rules:
        </Typography>
        {controlRules.map(({ enabled, Editor, onToggle, label, icon }, i) => {
          const isEnabled = enabled(rules) ?? false;
          return (
            <React.Fragment key={i}>
              <Typography className='px-[100px] py-[24px] flex flex-wrap items-center'>
                <Switch
                  checked={isEnabled}
                  onChange={(e) => updateRule(onToggle(e, rules))}
                />
                <i
                  className={classNames([
                    icon,
                    'ml-[24px]',
                    'mr-[12px]',
                    'text-xl',
                  ])}
                ></i>
                <span>{label(rules, true)}</span>
                {isEnabled && Editor && (
                  <Editor rules={rules} onChange={updateRule} />
                )}
              </Typography>
              <Divider />
            </React.Fragment>
          );
        })}
      </div>
    </StepContainer>
  );
};
