import { Box, Stack, Typography } from '@mui/material';
import type { ControlViolation } from '@repo/api-gw-sdk';

import OptionMenu from '@/@core/components/option-menu';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@/components/accordion';
import {
  ControlIndicator,
  controlKeysPriorities,
} from '@/components/controls/controlIndicator';
import { Loader } from '@/components/layout/loading';
import { Icon } from '@/components/shared/icon';
import { useRoles } from '@/contexts/useRoles';
import { useDAL } from '@/data/dal';
import { ControlsSeverity } from '@/data/inventory/data';

export const BackupStatusContent = ({ resourceId }: { resourceId: string }) => {
  const { isAuthorized } = useRoles();
  const dal = useDAL();
  const { body } = dal.inventory.violations.list(resourceId);
  if (!body?.controlViolations) {
    return <Loader />;
  }

  const groupedResponse = body.controlViolations.reduce<
    Record<string, ControlViolation[]>
  >((acc, violation) => {
    const key = violation.muted
      ? 'muted'
      : violation.control.severity.toLowerCase();

    acc[key] = acc[key] || [];
    acc[key].push(violation);

    return acc;
  }, {});

  return (
    <Box className='mt-[12px]'>
      {controlKeysPriorities.map((level) => {
        if (!groupedResponse[level]) {
          return null;
        }

        const groupedControls =
          groupedResponse[level].reduce<Record<string, ControlViolation[]>>(
            (acc, violation) => {
              const priority = violation.control.severity.toLowerCase();
              acc[priority] = acc[priority] || [];
              acc[priority].push(violation);
              return acc;
            },
            {}
          ) || {};

        return (
          <Accordion key={level} defaultExpanded>
            <AccordionSummary>
              <Stack
                className='w-full font-semibold'
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Box component={'span'}>
                  {`${(groupedResponse[level] || []).length} ${level === 'muted' ? ' muted violations' : `${level}-severity control violations`}`}
                </Box>
              </Stack>
            </AccordionSummary>
            <AccordionDetails className='pr-0'>
              {(level === 'muted'
                ? controlKeysPriorities.filter((x) => x !== 'muted')
                : [level]
              ).map((internalLevel, index) => {
                if (!groupedControls[internalLevel]?.length) {
                  return null;
                }

                return (
                  <Box key={internalLevel}>
                    {level === 'muted' && (
                      <Typography
                        variant='subtitle1'
                        className='uppercase'
                        sx={{
                          marginBottom: '16px',
                          marginTop: index === 0 ? '4px' : '24px',
                        }}
                      >{`${internalLevel}-severity controls`}</Typography>
                    )}
                    {groupedControls[internalLevel].map((violation) => {
                      return (
                        <Stack
                          key={violation.control.name}
                          direction={'row'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          className='py-[6px]'
                        >
                          <Box>
                            <ControlIndicator
                              backgroundColor={ControlsSeverity[level].color}
                            />
                            <Box component={'span'} className='ml-[16px]'>
                              {violation.control.name}
                            </Box>
                          </Box>
                          <Stack direction={'row'} alignItems={'center'}>
                            <OptionMenu
                              iconClassName='text-textPrimary'
                              icon='material-symbols-more-horiz'
                              options={[
                                ...(isAuthorized('update:issues')
                                  ? [level === 'muted' ? 'Unmute' : 'Mute']
                                  : []),
                                'Control settings',
                                'Learn more',
                              ]}
                              onOptionSelected={(option) => {
                                if (option === 'Mute' || option === 'Unmute') {
                                  void dal.inventory.violations.update(
                                    violation.id,
                                    {
                                      id: violation.id,
                                      resourceId: violation.resourceId,
                                      muted: !violation.muted,
                                    }
                                  ); // TODO: Get updated resource
                                } else if (option === 'Control settings') {
                                  // TODO: navigate to control settings
                                  console.log('Control settings');
                                } else if (option === 'Learn more') {
                                  // TODO: implement learn more
                                  console.log('Learn more');
                                }
                              }}
                            />
                            <Icon iconClass='material-symbols-info-outline' />
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Box>
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};
