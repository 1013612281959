import type { FileSearchRecord } from '@repo/api-gw-sdk';
import { useRouter } from 'next/navigation';

import { InstancePanel } from '@/components/panels/instancePanel';
import { Panels } from '@/components/panels/panels';
import { useFields } from '@/components/table/hooks';
import { useRoles } from '@/contexts/useRoles';
import { useWorkspace } from '@/contexts/useWorkspace';
import { useTranslation } from '@/i18n/client';

import { fileSearchFields } from './fileSearchFields';
import { getParentPath } from './searchUtils';

export const FileSearchInstancePanel = ({
  entity,
}: {
  entity: FileSearchRecord;
}) => {
  const { t } = useTranslation('properties');
  const router = useRouter();
  const { isAuthorized } = useRoles();
  const fields = useFields(fileSearchFields);
  const { rightPanel } = useWorkspace();
  const { setComponent, setIsOpen } = rightPanel;

  const actions = [
    ...(isAuthorized('create:restore')
      ? [
          {
            title: t('actions.restore'),
            primary: true,
            icon: 'mdi-backup-restore',
            execute: () =>
              setComponent({
                panel: Panels.RestoreFileWizard,
                props: {
                  entity,
                },
              }),
          },
        ]
      : []),
    {
      title: t('actions.reveal'),
      primary: true,
      icon: 'mingcute-directory-line',
      execute: (entity: FileSearchRecord) =>
        router.push(
          `/explorer?resourceId=${entity.resourceId}&path=${getParentPath(entity.path)}`
        ),
    },
  ];
  return (
    <InstancePanel
      entity={entity}
      resourceId={entity.resourceId}
      onClose={() => setIsOpen(false)}
      fields={fields}
      actions={actions}
      isLoading={false}
      openSections={{
        Properties: true,
        SourceLocationFields: true,
        BackupDetailsFields: false,
      }}
    />
  );
};
