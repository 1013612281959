// Third-party Imports
import { styled } from '@mui/material';

// Type Imports
import type { RootStylesType } from '../types';

type StyledMenuPrefixProps = RootStylesType & {
  firstLevel?: boolean;
  isCollapsed?: boolean;
  isHovered?: boolean;
};

const StyledMenuPrefix = styled('span')<StyledMenuPrefixProps>`
  display: ${({ firstLevel, isCollapsed, isHovered }) =>
    firstLevel && isCollapsed && !isHovered ? 'none' : 'flex'};
  ${({ rootStyles }) => rootStyles};
`;

export default StyledMenuPrefix;
