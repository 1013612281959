import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import {
  getCombineFilter,
  type CombineCondition,
  type Condition,
} from '@/types/advanceFilter';

import { FiltersPanelWrapper } from './filtersPanelWrapper';

export const FiltersDrillDownPanel = ({
  Component,
  onBack,
  title,
  subtitle,
  columnFilters,
}: {
  columnFilters: Condition | undefined;
  title: string;
  subtitle?: string;
  Component: ({ filter }: { filter: CombineCondition }) => React.ReactNode;
  onBack: (columnFilters: Condition | undefined) => void;
}) => {
  const [filter, setFilter] = useState<CombineCondition>(
    getCombineFilter(columnFilters)
  );

  useEffect(() => {
    setFilter(getCombineFilter(columnFilters));
  }, [columnFilters]);

  return (
    <FiltersPanelWrapper
      title={title}
      subtitle={subtitle}
      onBack={() => onBack(columnFilters)}
    >
      <Box className='px-[24px]'>{<Component filter={filter} />}</Box>
    </FiltersPanelWrapper>
  );
};
