import { Box, Button, Divider, Tooltip, Typography } from '@mui/material';
import type { InventoryVolume } from '@repo/api-gw-sdk';
import { Fragment } from 'react';

import { Icon } from '@/components/shared/icon';
import { useRoles } from '@/contexts/useRoles';
import { useWorkspace } from '@/contexts/useWorkspace';
import fileSizeFormatter from '@/utils/fileSizeFormatter';

import { SidePanelPropsTable } from './shared';

import { Panels } from '../../panels';

export const VolumesTab = (props: {
  entityId: string;
  volumes: InventoryVolume[];
  resourceId: string;
}) => {
  const { isAuthorized } = useRoles();
  const { rightPanel } = useWorkspace();
  const { setComponent } = rightPanel;

  return (
    <Box className='px-[40px] mt-[40px]'>
      <Typography fontSize={'20px'} className='mb-[12px]'>
        {`${props.volumes.length} Available Volumes`}
      </Typography>
      {props.volumes.map((volume, index) => (
        <Fragment key={volume.id}>
          <SidePanelPropsTable>
            <tbody>
              <tr>
                <td className='props-col align-middle'>{`Volume ${index + 1}`}</td>
                <td>
                  <Box className='flex justify-end'>
                    {isAuthorized('update:conversion') &&
                      false && ( // TODO: Modify restore volume wizard to work with+without snapshot selection
                        <Tooltip title='Convert to EBS Snapshot'>
                          <Icon
                            onClick={() =>
                              setComponent({
                                panel: Panels.RestoreVolumeWizard,
                                props: {
                                  snapshotId: '', // TODO: pay attention when enabling this button
                                  instanceId: props.entityId,
                                  volumeId: volume.id,
                                  volumeRegion: volume.regionName,
                                  actionType: 'snapshot',
                                },
                              })
                            }
                            className='mr-[10px]'
                            iconClass='material-symbols-repeat-rounded'
                          />
                        </Tooltip>
                      )}
                    {isAuthorized('create:restore') &&
                      false && ( // TODO: Modify restore volume wizard to work with+without snapshot selection
                        <Button
                          variant='outlined'
                          size='small'
                          onClick={() =>
                            setComponent({
                              panel: Panels.RestoreVolumeWizard,
                              props: {
                                snapshotId: '', // TODO: pay attention when enabling this button
                                instanceId: props.entityId,
                                volumeId: volume.id,
                                volumeRegion: volume.regionName,
                                actionType: 'volume',
                              },
                            })
                          }
                        >
                          Restore Volume
                        </Button>
                      )}
                  </Box>
                </td>
              </tr>
              <tr>
                <td className='props-col'>ID</td>
                <td>{props.resourceId}</td>
              </tr>
              <tr>
                <td className='props-col'>Path</td>
                <td>{volume.path}</td>
              </tr>
              <tr>
                <td className='props-col'>Size</td>
                <td>
                  {fileSizeFormatter(
                    volume.volumeSettings.size * 1000 * 1000 * 1000
                  )}
                </td>
              </tr>
            </tbody>
          </SidePanelPropsTable>
          {index !== props.volumes.length - 1 && (
            <Divider className='my-[24px]' />
          )}
        </Fragment>
      ))}
    </Box>
  );
};
