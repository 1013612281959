import type { InventoryResource } from '@repo/api-gw-sdk';
import type { TFunction } from 'i18next';

import {
  apps,
  accountId,
  backupPolicies,
  backupRegions,
  backupStorageSize,
  dataClasses,
  environment,
  lastBackup,
  resourceId,
  resourceName,
  backupStatus,
  resourceType,
  sourceRegion,
  sourceStorageSize,
  subnets,
  tags,
  vpc,
  vaults,
  id,
  genericSnapshotsCount,
} from '@/components/queryBuilder/properties';
import { createColumns } from '@/components/queryBuilder/propertiesUtils';

export type InventoryResourceWithAction = InventoryResource & {
  action?: string;
};

const columns = [
  id,
  backupStatus,
  resourceName,
  resourceId,
  resourceType,
  dataClasses,
  environment,
  apps,
  tags,

  backupPolicies,
  vaults,
  lastBackup,
  backupRegions,
  sourceStorageSize,
  backupStorageSize,
  genericSnapshotsCount,

  accountId,
  sourceRegion,
  vpc,
  subnets,
];

export const getColumns = (t: TFunction) =>
  createColumns<InventoryResource>(columns, t);
