import type { CSSObject } from '@mui/material';
import classnames from 'classnames';
import { useEffect } from 'react';
import type { HTMLAttributes } from 'react';

import type { VerticalNavState } from '../../contexts/verticalNavContext';
import { verticalNavToggleDuration } from '../../defaultConfigs';
import useVerticalNav from '../../hooks/useVerticalNav';
import StyledVerticalNav from '../../styles/vertical/StyledVerticalNav';
import StyledVerticalNavBgColorContainer from '../../styles/vertical/StyledVerticalNavBgColorContainer';
import StyledVerticalNavContainer from '../../styles/vertical/StyledVerticalNavContainer';
import { verticalNavClasses } from '../../utils/menuClasses';

export type VerticalNavProps = HTMLAttributes<HTMLHtmlElement> & {
  width?: VerticalNavState['width'];
  collapsedWidth?: VerticalNavState['collapsedWidth'];
  defaultCollapsed?: boolean;
  backgroundImage?: string;
  transitionDuration?: VerticalNavState['transitionDuration'];
  customStyles?: CSSObject;
};

const VerticalNav = (props: VerticalNavProps) => {
  // Props
  const {
    width = 260,
    collapsedWidth = 60,
    transitionDuration = verticalNavToggleDuration,
    className,
    customStyles,
    children,
    ...rest
  } = props;

  // Hooks
  const {
    updateVerticalNavState,
    isCollapsed: isCollapsedContext,
    width: widthContext,
    isHovered: isHoveredContext,
    collapsing: collapsingContext,
    transitionDuration: transitionDurationContext,
  } = useVerticalNav();

  // UseEffect, update verticalNav state to set initial values and update values on change
  useEffect(() => {
    updateVerticalNavState({
      width,
      collapsedWidth,
      transitionDuration,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, collapsedWidth, updateVerticalNavState]);

  useEffect(() => {
    setTimeout(() => {
      updateVerticalNavState({
        collapsing: false,
      });
    }, transitionDuration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsedContext]);

  // Handle VerticalNav Hover Event
  const handleVerticalNavHover = () => {
    /* If verticalNav is collapsed then only hover class should be added to verticalNav
      and hover functionality should work (expand verticalNav width) */
    if (isCollapsedContext && !isHoveredContext) {
      updateVerticalNavState({ isHovered: true });
    }
  };

  // Handle VerticalNav Hover Out Event
  const handleVerticalNavHoverOut = () => {
    // If verticalNav is collapsed then only remove hover class should contract verticalNav width
    if (isCollapsedContext && isHoveredContext) {
      updateVerticalNavState({ isHovered: false });
    }
  };

  return (
    <StyledVerticalNav
      width={!widthContext ? collapsedWidth : width}
      collapsedWidth={collapsedWidth}
      collapsing={collapsingContext}
      customStyles={customStyles}
      transitionDuration={transitionDurationContext}
      className={classnames(
        verticalNavClasses.root,
        {
          [verticalNavClasses.collapsed]: isCollapsedContext,
          [verticalNavClasses.hovered]: isHoveredContext,
          [verticalNavClasses.collapsing]: collapsingContext,
        },
        className
      )}
      {...rest}
    >
      {/* VerticalNav Container for hover effect when verticalNav is collapsed */}
      <StyledVerticalNavContainer
        width={widthContext}
        className={verticalNavClasses.container}
        transitionDuration={transitionDurationContext}
        {
          /* Toggle verticalNav on hover only when isPopoutWhenCollapsedContext(default false) is false */
          ...(isCollapsedContext && {
            onMouseEnter: handleVerticalNavHover,
            onMouseLeave: handleVerticalNavHoverOut,
          })
        }
      >
        {/* VerticalNav Container to apply styling like background */}
        <StyledVerticalNavBgColorContainer
          className={verticalNavClasses.bgColorContainer}
        >
          {children}
        </StyledVerticalNavBgColorContainer>
      </StyledVerticalNavContainer>
    </StyledVerticalNav>
  );
};

export default VerticalNav;
