import type { AccessorKeyColumnDef } from '@tanstack/react-table';
import type { TFunction } from 'i18next';
import { useMemo } from 'react';

import { useTranslation } from '@/i18n/client';
import type { FilterProperty } from '@/types/advanceFilter';

import { createFields } from '../queryBuilder/propertiesUtils';

export const useColumns = <T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getColumns: (t: TFunction) => AccessorKeyColumnDef<T, any>[]
) => {
  const { t } = useTranslation('properties');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useMemo<AccessorKeyColumnDef<T, any>[]>(
    () => getColumns(t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};

export const useFields = (properties: FilterProperty[]) => {
  const { t } = useTranslation('properties');

  return useMemo(
    () => createFields(properties, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
