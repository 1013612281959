// Third-party Imports
import { styled } from '@mui/material';

// Type Imports
import type { SubMenuContentProps } from '../components/vertical-menu/SubMenuContent';

const StyledSubMenuContent = styled('div')<SubMenuContentProps>`
  display: none;
  overflow: hidden;
  z-index: 999;
  transition: ${({ transitionDuration }) =>
    `block-size ${transitionDuration}ms ease-in-out`};
  box-sizing: border-box;

  ${({ isCollapsed, level, isHovered }) =>
    isCollapsed &&
    level === 0 &&
    !isHovered &&
    `
      block-size: 0 !important;
    `}

  ${() =>
    `
      position: static !important;
      transform: none !important;
      `}

  ${({ browserScroll }) =>
    browserScroll &&
    `overflow-y: auto; max-block-size: calc((var(--vh, 1vh) * 100));`}


  ${({ rootStyles }) => rootStyles};
`;

export default StyledSubMenuContent;
