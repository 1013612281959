import { useState } from 'react';

import { SidePanelWrapper } from '@/components/layout/sidePanel/sidePanelWrapper';
import { Icon } from '@/components/shared/icon';
import { useWorkspace } from '@/contexts/useWorkspace';

import type { Step } from './Step';

export interface WizardProps<T> {
  onFinish: (state: T) => boolean;
  onAbort: () => void;
  validateStep?: (state: T) => string | undefined;
  validate?: (state: T) => string | undefined;
  title: string;
  initialState: T;
  flow: Step<T>[];
  stepperLabels?: string[];
}

export const Wizard = <T,>(props: WizardProps<T>) => {
  const [steps, setSteps] = useState(props.flow);
  const currentStep = steps[steps.length - 1];
  const [state, setState] = useState<T>(props.initialState);
  const [err, setErr] = useState<string | undefined>();
  const { rightPanel } = useWorkspace();
  const { setIsOpen } = rightPanel;
  const abort = () => {
    props.onAbort();
    setIsOpen(false);
  };

  const back = () => {
    if (steps.length <= 1) {
      abort();
    } else {
      setSteps(steps.slice(0, steps.length - 1));
    }
  };

  const next = (step: Step<T> | undefined) => {
    const err = props.validateStep?.(state);
    if (err) {
      setErr(err);
      return;
    } else {
      setErr(undefined);
    }

    if (step) {
      setSteps([...steps, step]);
    }
  };

  const finish = () => {
    const err = props.validate?.(state);
    if (err) {
      setErr(err);
      return;
    } else {
      setErr(undefined);
    }

    props.onFinish(state) && setIsOpen(false);
  };

  return (
    <SidePanelWrapper
      title={props.title}
      icon={
        <Icon
          iconClass={`material-symbols-chevron-left-rounded`}
          className='cursor-pointer'
          onClick={back}
        />
      }
      close={abort}
    >
      <currentStep.Component
        stepperLabels={props.stepperLabels ?? []}
        currentStep={currentStep}
        back={back}
        abort={abort}
        error={err}
        state={state}
        setState={setState}
        finish={finish}
        setNextStep={next}
      />
    </SidePanelWrapper>
  );
};
