'use client';

import {
  Box,
  Checkbox,
  Container,
  Divider,
  Skeleton,
  styled,
  TablePagination,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type {
  Cell,
  ColumnDef,
  PaginationState,
  Row,
  RowSelectionState,
  SortingState,
  Table as TableType,
} from '@tanstack/react-table';
import classnames from 'classnames';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';

import tableStyles from '@core/styles/table.module.css';

import { useWorkspace } from '@/contexts/useWorkspace';
import { useTranslation } from '@/i18n/client';
import type { Condition } from '@/types/advanceFilter';

import { ColumnSelector } from './columnsPopup';
import { FiltersBar } from './filtersBar';

import {
  ActionBarButton,
  ActionsBar,
  ActionsToolBar,
} from '../shared/actionbar';
import { Icon } from '../shared/icon';

const StyledTable = styled('table')({
  '& tbody tr': {
    cursor: 'pointer',
  },
  '& tbody td': {
    verticalAlign: 'middle',
  },
});

interface RowData<T> {
  data: T[];
  openFilters?: boolean;
  showFiltersBar?: boolean;
  isLoading: boolean;
  isMultiSelect: boolean;
  disableSorting?: boolean;
  sorting?: SortingState;
  selectedId?: string;
  columnSelection?: boolean;
  columns: ColumnDef<T>[];
  columnFilters?: Condition;
  hiddenColumns?: string[];
  disableSelection?: boolean;
  hideHeaders?: boolean;
  pagination?: {
    pageIndex: number;
    pageSize: number;
    recordsCount?: number;
    onChange: (pagination: PaginationState) => void;
  };
  onRowSelectionChange?: (entities: T[]) => void;
  onSortingChanged?: (state: SortingState) => void;
  onColumnFiltersChange?: (filter: Condition) => void;
  getSubRows?: (row: T) => T[];
  getDynamicColProps?: (cell: Cell<T, unknown>) => Record<string, unknown>;
  commands?: {
    title: string;
    execute: (selectedEntities: T[]) => void;
  }[];
  expander?: {
    getRowCanExpand: (row: Row<T>) => boolean;
    renderSubComponent: (props: { row: Row<T> }) => JSX.Element;
  };
}

const getExpanderColumn = <T,>(): ColumnDef<T> => ({
  id: 'expander',
  enableHiding: false,
  header: () => null,
  cell: ({ row }) =>
    row.getCanExpand() ? (
      <button
        {...{
          onClick: row.getToggleExpandedHandler(),
          style: { cursor: 'pointer' },
        }}
      >
        {row.getIsExpanded() ? '👇' : '👉'}
      </button>
    ) : (
      '🔵'
    ),
});

const getSelectionColumn = <T,>(): ColumnDef<T> => ({
  id: 'select',
  enableHiding: false,
  size: 20,
  cell: ({ row }) => (
    <Checkbox
      {...{
        checked: row.getIsSelected(),
        disabled: !row.getCanSelect(),
        indeterminate: row.getIsSomeSelected(),
        onChange: row.getToggleSelectedHandler(),
      }}
    />
  ),
});

export const Table = <T extends object>({
  data,
  openFilters,
  showFiltersBar = false,
  hiddenColumns,
  isMultiSelect,
  columns,
  sorting,
  isLoading,
  pagination,
  columnSelection = true,
  selectedId,
  disableSorting,
  disableSelection,
  onSortingChanged,
  onRowSelectionChange,
  getSubRows,
  getDynamicColProps,
  onColumnFiltersChange,
  columnFilters,
  commands,
  expander,
  hideHeaders,
}: RowData<T>) => {
  const selectedIndex = data.findIndex((x) => 'id' in x && x.id === selectedId);
  const [rowSelection, setRowSelection] = useState({});
  const { leftPanel } = useWorkspace();
  const { setIsOpen, setComponent, isOpen, Component } = leftPanel;

  useEffect(() => {
    if (selectedIndex >= 0) {
      setRowSelection({ [selectedIndex]: true });
    } else {
      setRowSelection({});
    }
  }, [selectedIndex]);

  const recordsCount = useRef(pagination?.recordsCount || data.length);
  const extendedColumns = useMemo(
    () => [
      ...(isMultiSelect && !disableSelection ? [getSelectionColumn<T>()] : []),
      ...(expander ? [getExpanderColumn<T>()] : []),
      ...columns,
    ],
    [isMultiSelect, disableSelection, expander, columns]
  );
  const { t } = useTranslation();

  if (
    typeof pagination?.recordsCount === 'number' &&
    recordsCount.current !== pagination.recordsCount
  ) {
    recordsCount.current = pagination.recordsCount;
  }

  const table = useReactTable({
    data,
    columns: extendedColumns,
    enableSortingRemoval: false,
    getRowCanExpand: expander?.getRowCanExpand,
    initialState: {
      columnVisibility: hiddenColumns?.reduce<Record<string, boolean>>(
        (agg, x) => {
          agg[x] = false;
          return agg;
        },
        {}
      ),
    },
    state: {
      sorting,
      rowSelection,
      ...(pagination
        ? {
            pagination: {
              pageIndex: pagination.pageIndex,
              pageSize: pagination.pageSize,
            },
          }
        : {}),
    },
    enableMultiRowSelection() {
      return true;
    },
    getSubRows: (row) => getSubRows?.(row),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: (state) => {
      if (!disableSorting && sorting && onSortingChanged) {
        const newState = typeof state === 'function' ? state(sorting) : state;
        onSortingChanged(newState);
      }
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: (state) => {
      const newState =
        typeof state === 'function' ? state(rowSelection) : state;

      setRowSelection(newState);
      onRowSelectionChange?.(getSelectedEntities(table, newState));
    },
  });

  useEffect(() => {
    if (openFilters) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!Component || Component.panel === 'Filters') {
      setComponent({
        panel: 'Filters',
        props: {
          columns: table.getAllColumns(),
          columnFilters,
          onChange: onColumnFiltersChange ?? (() => {}),
        },
      });
    }

    if (Component?.panel === 'FiltersDrillDown') {
      setComponent({
        panel: Component.panel,
        props: {
          ...Component.props,
          columnFilters,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnFilters]);

  return (
    <>
      {showFiltersBar && columnFilters && (
        <FiltersBar
          columnFilters={columnFilters}
          onChange={onColumnFiltersChange ?? (() => {})}
        />
      )}
      {(isMultiSelect || commands?.length || pagination || columnSelection) && (
        <ActionsBar position='static' className='mb-0'>
          <Divider />
          <Container
            maxWidth={false}
            disableGutters
            sx={{ paddingRight: '30px' }}
          >
            <ActionsToolBar disableGutters>
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span>
                  {isMultiSelect && !disableSelection && (
                    <Checkbox
                      className='mt-[-3px] ml-[12px]'
                      checked={table.getIsAllRowsSelected()}
                      indeterminate={table.getIsSomeRowsSelected()}
                      onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                  )}
                  <Typography component={'span'} className='ml-[25px]'>
                    {isLoading
                      ? 'Loading...'
                      : `${recordsCount.current ?? 0} resources found`}
                  </Typography>
                </span>
                {commands?.map((cmd, i) => (
                  <ActionBarButton
                    variant='outlined'
                    className='text-textSecondary ml-[20px]'
                    key={i}
                    onClick={() => {
                      const selectedEntities = getSelectedEntities(
                        table,
                        table.getState().rowSelection
                      );
                      cmd.execute(selectedEntities);
                    }}
                  >
                    {cmd.title}
                  </ActionBarButton>
                ))}
              </Box>
              {pagination && (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component='div'
                  count={recordsCount.current}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageIndex}
                  onPageChange={(_, page) => {
                    table.setPageIndex(page);
                    pagination.onChange({
                      pageSize: pagination.pageSize,
                      pageIndex: page,
                    });
                  }}
                  slotProps={{
                    select: {
                      sx: {
                        marginTop: '2px',
                        marginRight: '17px',
                        color: 'var(--mui-palette-text-primary)',
                      },
                      inputProps: { 'aria-label': t('components.rowsPerPage') },
                      IconComponent: () => (
                        <i className='material-symbols-arrow-drop-down-rounded' />
                      ),
                    },
                  }}
                  labelDisplayedRows={({ from, to, count }) => (
                    <span className='flex items-center'>
                      <Divider
                        component='span'
                        orientation='vertical'
                        className='mr-[25px] h-[22px] inline-flex'
                      />
                      <Typography component='span'>{`${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`}</Typography>
                    </span>
                  )}
                  labelRowsPerPage={
                    <Typography component={'span'}>
                      {t('components.table.rowsPerPage')}
                    </Typography>
                  }
                  onRowsPerPageChange={(e) => {
                    const pageSize = Number(e.target.value);
                    const pagesCount = Math.ceil(
                      recordsCount.current / pageSize
                    );

                    table.setPageSize(pageSize);
                    pagination.onChange({
                      pageIndex: Math.min(pagination.pageIndex, pagesCount - 1),
                      pageSize: pageSize,
                    });
                  }}
                />
              )}
              {columnSelection && (
                <>
                  <Divider
                    orientation='vertical'
                    flexItem
                    className='my-[15px] mr-[20px]'
                  />
                  <Tooltip
                    title={t('components.table.columnsFilterButton')}
                    className='mr-[12px]'
                  >
                    <Icon
                      iconClass='material-symbols-filter-alt-outline'
                      isActive={isOpen}
                      onClick={() => {
                        setIsOpen(!isOpen);
                        setComponent({
                          panel: 'Filters',
                          props: {
                            columns: table.getAllColumns(),
                            columnFilters,
                            onChange: onColumnFiltersChange ?? (() => {}),
                          },
                        });
                      }}
                    />
                  </Tooltip>
                  <ColumnSelector columns={table.getAllColumns()} />
                </>
              )}
            </ActionsToolBar>
          </Container>
        </ActionsBar>
      )}
      <div className='overflow-x-auto mt-[4px]'>
        <StyledTable className={tableStyles.table}>
          {!hideHeaders && (
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      style={{ width: `${header.getSize()}px` }}
                      className={classnames({
                        'cursor-pointer select-none':
                          !disableSorting && header.column.getCanSort(),
                        [tableStyles.sorted]: header.column.getIsSorted(),
                      })}
                      onClick={() => {
                        if (!disableSorting && header.column.getCanSort()) {
                          header.column.toggleSorting();
                        }
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div className='whitespace-pre-line justify-between flex'>
                            <Typography variant='subtitle1'>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </Typography>
                            {header.column.getCanSort() && (
                              <Typography
                                variant='subtitle1'
                                className={classnames('mt-[-8px]', {
                                  collapse: !header.column.getIsSorted(),
                                })}
                              >
                                <Icon
                                  iconClass={
                                    header.column.getIsSorted() === 'asc'
                                      ? 'ri-arrow-down-s-line'
                                      : 'ri-arrow-up-s-line'
                                  }
                                  sx={{
                                    i: {
                                      color: 'var(--mui-palette-text-gray1)',
                                    },
                                  }}
                                />
                              </Typography>
                            )}
                          </div>
                        </>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
              <tr style={{ height: '4px' }}></tr>
            </thead>
          )}
          <TableBody<T>
            data={data}
            isLoading={isLoading}
            table={table}
            expander={expander}
            getDynamicColProps={getDynamicColProps}
          />
        </StyledTable>
      </div>
    </>
  );
};

const LoadingState = ({
  numOfRows,
  numOfColumns,
}: {
  numOfRows: number;
  numOfColumns: number;
}) => {
  return (
    <tbody>
      {[...Array(numOfRows)].map((_, i) => (
        <tr key={i}>
          {[...Array(numOfColumns)].map((_, j) => (
            <td key={j} className='text-center'>
              <Skeleton variant='text' />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

const NoDataState = ({ columnCount }: { columnCount: number }) => {
  const { t } = useTranslation();

  return (
    <tbody>
      <tr>
        <td colSpan={columnCount} className='text-center'>
          {t('components.table.noData')}
        </td>
      </tr>
    </tbody>
  );
};

function TableBody<T>({
  table,
  data,
  expander,
  isLoading,
  getDynamicColProps,
}: {
  table: TableType<T>;
  isLoading: boolean;
  getDynamicColProps?: (cell: Cell<T, unknown>) => Record<string, unknown>;
  data: T[];
  expander?: {
    getRowCanExpand: (row: Row<T>) => boolean;
    renderSubComponent: (props: { row: Row<T> }) => JSX.Element;
  };
}) {
  if (isLoading && !data.length) {
    return (
      <LoadingState
        numOfRows={10}
        numOfColumns={table.getVisibleFlatColumns().length}
      />
    );
  }

  if (!isLoading && table.getRowModel().rows.length === 0) {
    return <NoDataState columnCount={table.getVisibleFlatColumns().length} />;
  }

  if (table.getRowModel().rows.length > 0) {
    return (
      <tbody
        className={`${classnames({
          'opacity-30': isLoading,
        })} align-top`}
      >
        {table.getRowModel().rows.map((row) => {
          return (
            <Fragment key={row.id}>
              <tr
                className={
                  row.getIsSelected()
                    ? classnames({
                        [tableStyles.selected]: row.getIsSelected(),
                      })
                    : undefined
                }
                onClick={() => {
                  if (!isLoading) {
                    table.setRowSelection({ [row.id]: !row.getIsSelected() });
                  }
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} {...getDynamicColProps?.(cell)}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
              {expander && row.getIsExpanded() && (
                <tr>
                  <td colSpan={row.getVisibleCells().length}>
                    {expander.renderSubComponent({ row })}
                  </td>
                </tr>
              )}
            </Fragment>
          );
        })}
      </tbody>
    );
  }
}

function getSelectedEntities<T>(
  table: TableType<T>,
  newState: RowSelectionState
) {
  const rows = table.getRowModel().rows;
  const selectedIds = Object.keys(newState).reduce<T[]>((agg, index) => {
    if (newState[index]) {
      agg.push(rows[parseInt(index)].original);
    }
    return agg;
  }, []);

  return selectedIds;
}
