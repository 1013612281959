'use client';

import { styled } from '@mui/material';
import classnames from 'classnames';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import { PanelsMap, type PanelTypes } from '@/components/panels/panelsMap';
import { useWorkspace } from '@/contexts/useWorkspace';
import { PanelSize } from '@/contexts/workspaceContext';

export const LeftSidePanel = () => {
  const { leftPanel } = useWorkspace();
  const { isOpen, Component, setIsOpen } = leftPanel;

  return (
    <StyledPanel
      className='border-s-0 min-w-[380px] w-[380px] sticky'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      Component={Component}
    />
  );
};

export const RightSidePanel = () => {
  const { rightPanel } = useWorkspace();
  const { isOpen, Component, setIsOpen, panelSize } = rightPanel;

  let width = '840px';
  if ((panelSize as PanelSize) === PanelSize.Small) {
    width = '640px';
  } else if ((panelSize as PanelSize) === PanelSize.Large) {
    width = '840px';
  } else if ((panelSize as PanelSize) === PanelSize.Full) {
    width = 'calc(100vw - 59px)';
  } else if (typeof panelSize === 'number') {
    width = `${panelSize}px`;
  }

  return (
    <StyledPanel
      sx={{
        backgroundColor: 'var(--mui-palette-background-paper)',
        boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.2)',
        width: width,
        borderInlineStart:
          (rightPanel.panelSize as PanelSize) === PanelSize.Full
            ? '0'
            : '1px solid var(--border-color)',
        borderInlineEnd:
          (rightPanel.panelSize as PanelSize) === PanelSize.Full
            ? '0'
            : '1px solid var(--border-color)',
      }}
      className='right-0 border-e-0 fixed'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      Component={Component}
    />
  );
};

const SidePanel = ({
  className,
  isOpen,
  setIsOpen,
  Component,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  Component: PanelTypes | undefined;
  className: string;
}) => {
  const path = usePathname();

  useEffect(() => {
    setIsOpen(false);
  }, [path, setIsOpen]);

  const Panel = Component?.panel && PanelsMap[Component.panel];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const props = Component?.props || ({} as any);

  return (
    isOpen &&
    Panel && (
      <div className={classnames('h-dvh flex flex-col z-[5] top-0', className)}>
        <Panel {...props} />
      </div>
    )
  );
};

const StyledPanel = styled(SidePanel)({
  borderInlineStart: '1px solid var(--border-color)',
  borderInlineEnd: '1px solid var(--border-color)',
});
