'use client';

import type {
  DatesSetArg,
  DayCellContentArg,
  EventContentArg,
} from '@fullcalendar/core/index.js';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import { Button, Stack, Typography } from '@mui/material';
import type {
  Snapshot,
  SnapshotPropertiesVolumePropertiesInner,
} from '@repo/api-gw-sdk';
import { useEffect, useState } from 'react';

import VaultTag from '@/components/vaults/VaultTag';
import type { BackupVault } from '@/data/vaults/backupVault';

import { PITCalendarWrapper } from './PITCalendarWrapper';
import { SnapshotIndicator } from './SnapshotIndicator';
import { StyledPITPopperBottomBar } from './StyledPITPopperBottomBar';

export interface SnapshotMetadata {
  snapshotId: string;
  vaultId: string;
  generatedOn: Date;
  snapshot: Snapshot | undefined;
}
interface SnapshotCalendarProps {
  vaults?: BackupVault[];
  data?: SnapshotMetadata[];
  onDateRangeChanged: (startDate: Date, endDate: Date) => void;
  toSelect: 'snapshot' | 'volume';
  onSelected: (
    snapshot: SnapshotMetadata,
    vault: BackupVault,
    volume?: SnapshotPropertiesVolumePropertiesInner
  ) => void;
}

export const SnapshotCalendar = (props: SnapshotCalendarProps) => {
  const [selected, setSelected] = useState<Date | undefined>();
  const [snapshots, setSnapshots] = useState<
    {
      snapshotId: string;
      vaultId: string;
      generatedOn: Date;
      snapshot: Snapshot | undefined;
    }[]
  >([]);
  const [events, setEvents] = useState<
    {
      snapshotId?: string;
      vaultId?: string;
      vaultName?: string;
      snapshot: Snapshot | undefined;
      backgroundColor?: string;
      start?: Date;
    }[]
  >([]);

  useEffect(() => {
    if (!props.vaults || props.vaults.length === 0) return;
    if (!props.data || props.data.length === 0) return;

    // init events
    const newEvents = [];
    for (const snapshot of props.data ?? []) {
      if (snapshot.vaultId) {
        const vault = props.vaults.find((v) => v.id === snapshot.vaultId);
        if (vault) {
          newEvents.push({
            snapshotId: snapshot.snapshotId,
            vaultId: snapshot.vaultId,
            snapshot: snapshot.snapshot,
            backgroundColor: vault.backgroundColor,
            vaultName: vault.name,
            start: snapshot.generatedOn,
          });
        }
      }
    }

    setEvents(newEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.vaults]);

  useEffect(() => {
    if (!selected) return;
    const snapEvents = events
      .filter((e) => e.start?.toDateString() === selected.toDateString())
      .map((e) => ({
        snapshot: e.snapshot,
        snapshotId: e.snapshotId || '',
        vaultId: e.vaultId || '',
        vaultName: e.vaultName || '',
        generatedOn: e.start!,
      }));

    setSnapshots(snapEvents);
  }, [selected, events]);

  return (
    <>
      <Stack direction='row' alignItems='center' className='mb-[8px]'>
        <Typography variant='body2' className='mr-[8px]'>
          {`112 points in time were collected into ${props.vaults?.length} vaults`}
        </Typography>
        {props.vaults?.map((vault) => (
          <VaultTag key={vault.id} vault={vault} />
        ))}
      </Stack>
      <PITCalendarWrapper>
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView='dayGridMonth'
          weekends={true}
          fixedWeekCount={false}
          showNonCurrentDates={false}
          buttonText={{ today: 'Today' }}
          dayCellContent={renderDayCell}
          dayCellClassNames={(args) => {
            const date = new Date(args.date.toDateString());
            return selected?.getTime() === date.getTime()
              ? 'cell-selected'
              : '';
          }}
          dayCellDidMount={(args) => {
            args.el.onclick = () => {
              const dateStr = args.date.toDateString();
              const date = new Date(dateStr);
              setSelected(date);
            };
          }}
          selectable={true}
          dayHeaders={false}
          events={events}
          eventContent={renderEventContent}
          datesSet={(args: DatesSetArg) => {
            props.onDateRangeChanged(args.start, args.end);
            setSelected(undefined);
            setSnapshots([]);
          }}
          height='auto'
        />
      </PITCalendarWrapper>
      <StyledPITPopperBottomBar>
        {snapshots.length > 0 ? (
          snapshots.map((snap, i) => {
            const vault = props.vaults?.find((v) => v.id === snap.vaultId);
            if (!vault) return null;
            if (props.toSelect === 'snapshot') {
              return (
                <Stack
                  className='mb-[8px] w-full'
                  flexDirection='row'
                  justifyContent='space-between'
                  alignItems='center'
                  key={i}
                >
                  <Stack direction='row' alignItems='center'>
                    <VaultTag vault={vault} />
                    <Typography variant='body1'>{snap.snapshotId}</Typography>
                  </Stack>
                  <Button
                    disabled={!selected}
                    variant='outlined'
                    color='primary'
                    onClick={() => {
                      props.onSelected?.(snap, vault);
                    }}
                  >
                    Select
                  </Button>
                </Stack>
              );
            }
            if (!snap.snapshot) return null;
            if (!('properties' in snap.snapshot)) return null;
            return (
              <Stack key={i}>
                {snap.snapshot?.properties?.volumeProperties?.map((vol) => (
                  <Stack
                    direction='row'
                    key={vol.volumeId}
                    className='mb-[8px] w-full'
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <VaultTag vault={vault} />
                    <Typography variant='body1'>
                      {
                        //TODO: make this not ugly

                        //TODO: change to volumeName
                        vol.volumeId
                      }{' '}
                    </Typography>
                    <Typography variant='body1'>
                      {snap.snapshot?.dateCreated.toLocaleString()}
                    </Typography>
                    <Button
                      disabled={!selected}
                      variant='outlined'
                      color='primary'
                      onClick={() => {
                        props.onSelected?.(snap, vault, vol);
                      }}
                    >
                      Restore Volume
                    </Button>
                  </Stack>
                ))}
              </Stack>
            );
          })
        ) : (
          <Stack
            className='mb-[8px] w-full'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <span></span>
            <Button disabled variant='contained' color='primary'>
              Select
            </Button>
          </Stack>
        )}
      </StyledPITPopperBottomBar>
    </>
  );
};

function renderEventContent(args: EventContentArg) {
  return (
    <>
      <SnapshotIndicator
        backgroundColor={args.event.backgroundColor}
        title={args.event._def.extendedProps.vaultName}
      ></SnapshotIndicator>
    </>
  );
}

const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
function renderDayCell(arg: DayCellContentArg) {
  return (
    <div>
      {weekday[arg.date.getDay()]} {arg.dayNumberText}
    </div>
  );
}
