import { Tab, Tabs } from '@mui/material';
import type {
  BackupPolicy,
  BackupVault,
  InventoryVolume,
} from '@repo/api-gw-sdk';
import React, { useState } from 'react';

import { BackupPoliciesTab } from './instance/tabs/backupPoliciesTab';
import { OverviewTab } from './instance/tabs/overviewTab';
import { PointsInTimeTab } from './instance/tabs/pointsInTimeTab';
import { VolumesTab } from './instance/tabs/volumesTab';

import { Loader } from '../layout/loading';
import { SidePanelWrapper } from '../layout/sidePanel/sidePanelWrapper';

export interface InstancePanelProps<T> {
  entity: T;
  resourceId: string;
  fields: {
    id: string;
    title: (entity: T) => string;
    group: string | undefined;
    value: (entity: T) => React.ReactNode;
  }[];
  actions: {
    title: string;
    primary?: boolean;
    icon?: string;
    execute: (entity: T) => void;
  }[];
  openSections: {
    Properties: boolean;
    BackupDetailsFields: boolean;
    SourceLocationFields: boolean;
  };
}

const InstancePanelComponent = <
  T extends {
    id?: string;
    resourceId: string;
    path?: string;
    volumes?: InventoryVolume[];
    backupPolicies?: BackupPolicy[];
    vaults?: BackupVault[];
  },
>(
  props: InstancePanelProps<T>
) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const tabs = [
    {
      name: 'Overview',
      Component: OverviewTab,
    },
    ...(props.entity.id && props.entity.volumes?.length
      ? [
          {
            name: 'Volumes',
            Component: () => (
              <VolumesTab
                entityId={props.entity.id!}
                volumes={props.entity.volumes!}
                resourceId={props.resourceId}
              />
            ),
          },
        ]
      : []),
    ...(props.entity.backupPolicies?.length
      ? [
          {
            name: 'Backup policies',
            Component: () => (
              <BackupPoliciesTab
                activePolicies={props.entity.backupPolicies!}
              />
            ),
          },
        ]
      : []),
    ...(props.entity.id && props.entity.vaults?.length
      ? [
          {
            name: 'Points in time',
            Component: () => (
              <PointsInTimeTab
                entityId={props.entity.id!}
                vaults={props.entity.vaults!}
              />
            ),
          },
        ]
      : []),
  ];

  // Sometimes switching across resources we get to a resource without the selected tab in that case we go to the first tab
  const navigateToNonExistingTab = selectedIndex >= tabs.length;
  if (navigateToNonExistingTab) {
    setSelectedIndex(0);
  }
  const { Component } = navigateToNonExistingTab
    ? tabs[0]
    : tabs[selectedIndex];

  return (
    <>
      {tabs.length > 1 && (
        <Tabs
          onChange={(e, i) => setSelectedIndex(i)}
          value={selectedIndex}
          className='px-[40px] min-h-[50px]'
          sx={{ '.MuiTabs-scroller': { display: 'flex' } }}
        >
          {tabs.map((value) => (
            <Tab
              key={value.name}
              label={value.name}
              className='text-[14px] font-normal'
              disableRipple
            />
          ))}
        </Tabs>
      )}
      {Component && <Component {...props} />}
    </>
  );
};

export const InstancePanel = <
  T extends { id?: string; resourceId: string; path?: string },
>({
  isLoading,
  resourceId,
  onClose,
  entity,
  fields,
  openSections,
  actions,
}: {
  isLoading: boolean;
  resourceId: string;
  onClose: () => void;
  entity: T | undefined;
  fields: InstancePanelProps<T>['fields'];
  openSections: InstancePanelProps<T>['openSections'];
  actions: InstancePanelProps<T>['actions'];
}) => {
  if (isLoading) {
    return <Loader />;
  }

  if (!entity) {
    return null;
  }

  return (
    <SidePanelWrapper title={'Resource Details'} close={onClose} hideBorder>
      <InstancePanelComponent
        entity={entity}
        actions={actions}
        fields={fields}
        openSections={openSections}
        resourceId={resourceId}
      />
    </SidePanelWrapper>
  );
};
