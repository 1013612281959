import { capitalize } from '@mui/material';
import { CreateAccountInputCloudProviderEnum } from '@repo/api-gw-sdk';
import { useState } from 'react';

import { useEnvironment } from '@/contexts/useEnvironment';
import { useUser } from '@/contexts/useUser';
import { useDAL } from '@/data/dal';

export type AccountType = 'source' | 'vault' | 'restore';

const URLS: Record<AccountType, string> = {
  source:
    'https://eon-public-b2b628cc-1d96-4fda-8dae-c3b1ad3ea03b.s3.amazonaws.com/testing/source-account.yml',
  vault:
    'https://eon-public-b2b628cc-1d96-4fda-8dae-c3b1ad3ea03b.s3.amazonaws.com/testing/vault-account.yml',
  restore:
    'https://eon-public-b2b628cc-1d96-4fda-8dae-c3b1ad3ea03b.s3.amazonaws.com/testing/restore-account.yml',
};

const BASE_URL =
  'https://eu-central-1.console.aws.amazon.com/cloudformation/home?region=eu-central-1#/stacks/create/review?';

const popup = async ({
  url,
  title,
  width,
  height,
}: {
  url: string;
  title: string;
  width: number;
  height: number;
}) => {
  const { screen } = window;
  const left = screen.width / 2 - width / 2;
  const top = screen.height / 2 - height / 2;

  const newWindow = window.open(
    url,
    title,
    `scrollbars=yes,width=${width},height=${height},top=${top},left=${left},screenX=${left},screenY=${top}`
  );
  newWindow?.focus();

  await new Promise<void>((resolve) => {
    const interval = setInterval(() => {
      if (newWindow?.closed) {
        clearInterval(interval);
        resolve();
      }
    }, 500);
  });
};

export const useCreateAccount = (
  accountType: AccountType,
  onDone: (accountId: string) => void
) => {
  const dal = useDAL();
  const { user } = useUser();
  const { serviceAccountId } = useEnvironment();
  const [accountId, setAccountId] = useState('');
  const [roleName, setRoleName] = useState(`eon${capitalize(accountType)}Role`);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [isAccountCreated, setIsAccountCreated] = useState(false);
  const [hasError, setHasError] = useState(false);

  const createAccount = async () => {
    setIsPopupOpen(false);
    setIsCreatingAccount(true);
    const { id, success } = await dal.cloudAccounts[accountType]
      .create({
        cloudProvider: CreateAccountInputCloudProviderEnum.Aws,
        role: `arn:aws:iam::${accountId}:role/${roleName}`,
        name: accountId,
        regions:
          accountType === 'restore' || accountType === 'vault'
            ? ['us-east-1']
            : undefined,
      })
      .then((res) => ({ success: true, id: res.id }))
      .catch(() => ({ success: false, id: '' }));
    setIsCreatingAccount(false);
    if (success) {
      setIsAccountCreated(true);
      setTimeout(() => onDone(id), 1000);
    } else {
      setHasError(true);
    }
  };

  const openPopup = async () => {
    if (!accountId || !roleName || !user) {
      return;
    }
    const searchParams = new URLSearchParams();
    searchParams.append('templateURL', URLS[accountType]);
    searchParams.append('stackName', `EonBackup${capitalize(accountType)}`);
    searchParams.append('param_CustomerId', user.eonAccount.id);
    searchParams.append('param_RoleName', roleName);
    if (serviceAccountId)
      searchParams.append('param_ServiceAccountId', serviceAccountId);

    setIsPopupOpen(true);
    await popup({
      url: `${BASE_URL}${searchParams}`,
      title: 'AWSConsole',
      width: window.screen.width * 0.8,
      height: window.screen.height * 0.8,
    });
  };

  return {
    accountId,
    setAccountId,
    roleName,
    setRoleName,
    isPopupOpen,
    isCreatingAccount,
    isAccountCreated,
    hasError,
    retry: () => setHasError(false),
    openPopup,
    createAccount,
  };
};
