import {
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Typography,
  IconButton,
} from '@mui/material';
import { useRef, useState } from 'react';

import { useUser } from '@/contexts/useUser';
import { useDAL } from '@/data/dal';

export const ProjectDropdown = () => {
  const [open, setOpen] = useState(false);
  const { user, changeProject, changeRole, currentProjectId } = useUser();
  const dal = useDAL();
  const { body: projects } = dal.projects.get();

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleProjectSwitch = (projectId: string) => {
    handleClose();
    changeProject(projectId);

    if (
      !user?.selectedRole.isProjectRole ||
      user.roleToProjectsMappings[user.selectedRole.id].ids!.includes(projectId)
    ) {
      return;
    }

    const nextRole = Object.entries(user.roleToProjectsMappings).find((x) =>
      x[1].ids?.includes(projectId)
    )?.[0];

    if (nextRole) {
      changeRole(nextRole);
    }
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleToggle}
        className='gap-3 py-[8px] w-full bg-transparent rounded-none justify-start'
      >
        <i
          className={`material-symbols-credit-card-outline text-textPrimary`}
        />
        <Typography color='text.primary'>
          {projects?.projects?.find((x) => x.id === currentProjectId)?.name}
        </Typography>
      </IconButton>
      <Popper
        open={open}
        transition
        disablePortal
        placement='bottom-start'
        anchorEl={anchorRef.current}
        className='min-is-[160px] !mbs-4 z-[1]'
      >
        {({ TransitionProps, placement }) => (
          <Fade
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'right top',
            }}
          >
            <Paper className={'shadow-lg'}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList onKeyDown={handleClose}>
                  {projects?.projects?.map((project) => (
                    <MenuItem
                      key={project.id}
                      className='gap-3'
                      onClick={() => handleProjectSwitch(project.id)}
                      selected={currentProjectId === project.id}
                      value={project.id}
                    >
                      {project.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};
