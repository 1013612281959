import type { FileSearchRecord } from '@repo/api-gw-sdk';

import { useWorkspace } from '@/contexts/useWorkspace';
import { useTranslation } from '@/i18n/client';

import { AddNewAccount } from './steps/addNewAccount';
import { FileConfiguration } from './steps/fileConfiguration';
import { SelectSnapshot } from './steps/selectSnapshot';
import { SelectTargetAccount } from './steps/selectTargetAccount';

import { Panels } from '../panels/panels';
import type { Step } from '../wizard/Step';
import { Wizard } from '../wizard/Wizard';

export interface RestoreFileState {
  actionType: string;
  resourceIdForSnapshots?: string;
  snapshotId?: string;
  restoreAccountId?: string;
  files: { path: string; isDirectory: boolean }[];
  restoreBucketArn?: string;
  restoreBucketName?: string;
  restoreBucketRegion?: string;
  restoreBucketPrefix?: string;
  encryptionKeyId?: string;
}

const restoreFileFlow: Step<RestoreFileState> = {
  name: 'SelectSnapshotStep',
  Component: SelectSnapshot,
  next: [
    {
      name: 'SelectTargetAccountStep',
      Component: SelectTargetAccount,
      next: [
        {
          name: 'AddNewAccountStep',
          Component: AddNewAccount,
        },
        {
          name: 'FileConfiguration',
          Component: FileConfiguration,
        },
      ],
    },
  ],
};

const restoreFileFlowFromSnapshot: Step<RestoreFileState> = {
  name: 'SelectTargetAccountStep',
  Component: SelectTargetAccount,
  next: [
    {
      name: 'AddNewAccountStep',
      Component: AddNewAccount,
    },
    {
      name: 'FileConfiguration',
      Component: FileConfiguration,
    },
  ],
};

type Props =
  | {
      entity: FileSearchRecord;
      withSnapshot?: false;
    }
  | {
      path: string;
      type: string;
      snapshotId: string;
      withSnapshot: true;
    };

export const RestoreFileWizard = (props: Props) => {
  const { t } = useTranslation('restore');
  const { rightPanel } = useWorkspace();
  const { setIsOpen, setComponent } = rightPanel;

  const close = () => {
    if (props.withSnapshot) {
      setIsOpen(false);
    } else {
      setComponent({
        panel: Panels.FileSearchInstance,
        props: { entity: props.entity },
      });
    }

    return true;
  };

  const files = props.withSnapshot
    ? [{ path: props.path, isDirectory: props.type === 'd' }]
    : [{ path: props.entity.path, isDirectory: props.entity.type === 'd' }];

  const resourceIdForSnapshots = props.withSnapshot
    ? undefined
    : props.entity.resourceId;

  const snapshotId = props.withSnapshot ? props.snapshotId : undefined;

  return (
    <Wizard<RestoreFileState>
      onFinish={close}
      onAbort={close}
      flow={
        props.withSnapshot ? [restoreFileFlowFromSnapshot] : [restoreFileFlow]
      }
      initialState={{
        files,
        actionType: 'file',
        resourceIdForSnapshots,
        snapshotId,
      }}
      title={t('titles.file')}
      stepperLabels={['Point in Time', 'Restore Account', 'Configuration']}
    />
  );
};
