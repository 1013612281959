import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  ClickAwayListener,
  Fade,
  FormControlLabel,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from '@mui/material';
import type { Column } from '@tanstack/react-table';
import { useRef, useState, type SyntheticEvent } from 'react';

import { useTranslation } from '@/i18n/client';

import { getProperties } from '../queryBuilder/propertiesUtils';
import { PropertyGroups } from '../queryBuilder/propertyGroups';
import { Icon } from '../shared/icon';

interface ColumnSelectorProps<T> {
  columns: Column<T>[];
}

export const ColumnSelector = <T,>({ columns }: ColumnSelectorProps<T>) => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClose = (event: Event | SyntheticEvent) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const groupedColumns = columns
    .filter((x) => x.getCanFilter())
    .reduce<Record<string, Column<T>[]>>((acc, column) => {
      getProperties(column).forEach(({ group }) => {
        if (group) {
          acc[group] = acc[group] || [];
          acc[group].push(column);
        }
      });
      return acc;
    }, {});

  return (
    <>
      <Tooltip title={t('components.table.columnsSelectionButton')}>
        <Icon
          ref={anchorRef}
          iconClass='material-symbols-splitscreen-vertical-add-outline-rounded'
          isActive={open}
          onClick={() => setOpen(!open)}
        />
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement={'left-end'}
        transition
        disablePortal
        sx={{ zIndex: 1 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper className={'shadow-lg'}>
              <ClickAwayListener onClickAway={handleClose}>
                <Card
                  sx={{
                    boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.20)',
                    width: '300px',
                    borderRadius: 0,
                  }}
                >
                  <CardHeader
                    className='py-[12px] px-[24px]'
                    action={
                      <Tooltip title={'Close'}>
                        <Icon
                          iconClass='material-symbols-close-rounded'
                          onClick={handleClose}
                        />
                      </Tooltip>
                    }
                    sx={{
                      padding: '12px 24px',
                      borderBottom: '1px solid var(--mui-palette-divider)',
                    }}
                    title={
                      <Typography className='font-semibold'>
                        {t('components.table.columnsFilterButton')}
                      </Typography>
                    }
                  />
                  <CardContent
                    sx={{
                      height: 500,
                      overflow: 'scroll',
                      padding: '0',
                    }}
                  >
                    {Object.entries(groupedColumns).map(([group, columns]) => {
                      return (
                        <Box key={group}>
                          <Typography
                            variant='subtitle1'
                            className='px-[24px] py-[8px] my-[12px]'
                            sx={{
                              backgroundColor:
                                'var(--mui-palette-background-tableHeader)',
                              textTransform: 'uppercase',
                            }}
                          >
                            {PropertyGroups[group].title}
                          </Typography>
                          {columns.map((column) => {
                            return (
                              <div key={column.id}>
                                <FormControlLabel
                                  className='w-full'
                                  control={
                                    <Checkbox
                                      sx={{ padding: '10px 10px 10px 30px' }}
                                      size='small'
                                      checked={column.getIsVisible()}
                                      onChange={() => {
                                        column.toggleVisibility();
                                      }}
                                    />
                                  }
                                  label={`${column.columnDef.header}`}
                                />
                              </div>
                            );
                          })}
                        </Box>
                      );
                    })}
                  </CardContent>
                </Card>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};
