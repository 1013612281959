import {
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  IconButton,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';

import { useSettings } from '@core/hooks/useSettings';
import type { Mode } from '@core/types';

const ModeDropdown = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { settings, updateSettings } = useSettings();

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleModeSwitch = (mode: Mode) => {
    handleClose();

    if (settings.mode !== mode) {
      updateSettings({ mode: mode });
    }
  };

  const getModeIcon = () => {
    if (settings.mode === 'system') {
      return 'ri-computer-line';
    } else if (settings.mode === 'dark') {
      return 'ri-moon-clear-line';
    } else {
      return 'ri-sun-line';
    }
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleToggle}
        className='gap-3 py-[8px] w-full bg-transparent rounded-none justify-start'
      >
        <i className={`${getModeIcon()} text-textPrimary`} />
        <Typography
          className='capitalize'
          color='text.primary'
        >{`${settings.mode} Mode`}</Typography>
      </IconButton>
      <Popper
        open={open}
        transition
        disablePortal
        placement='bottom-start'
        anchorEl={anchorRef.current}
        className='min-is-[160px] !mbs-4 z-[1]'
      >
        {({ TransitionProps, placement }) => (
          <Fade
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'right top',
            }}
          >
            <Paper className={'shadow-lg'}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList onKeyDown={handleClose}>
                  <MenuItem
                    className='gap-3'
                    onClick={() => handleModeSwitch('light')}
                    selected={settings.mode === 'light'}
                  >
                    <i className='ri-sun-line text-[22px]' />
                    Light
                  </MenuItem>
                  <MenuItem
                    className='gap-3'
                    onClick={() => handleModeSwitch('dark')}
                    selected={settings.mode === 'dark'}
                  >
                    <i className='ri-moon-clear-line text-[22px]' />
                    Dark
                  </MenuItem>
                  <MenuItem
                    className='gap-3'
                    onClick={() => handleModeSwitch('system')}
                    selected={settings.mode === 'system'}
                  >
                    <i className='ri-computer-line text-[22px]' />
                    System
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default ModeDropdown;
