import { styled } from '@mui/material';
import type { PropsWithChildren } from 'react';

import type { VerticalNavContextProps } from '../../contexts/verticalNavContext';
import useVerticalNav from '../../hooks/useVerticalNav';
import { verticalNavClasses } from '../../utils/menuClasses';

interface StyledNavHeaderProps {
  isHovered?: VerticalNavContextProps['isHovered'];
  isCollapsed?: VerticalNavContextProps['isCollapsed'];
  collapsedWidth?: VerticalNavContextProps['collapsedWidth'];
  transitionDuration?: VerticalNavContextProps['transitionDuration'];
}

const StyledNavHeader = styled('div')<StyledNavHeaderProps>`
  padding: 0 15px;
  height: 59px;
  padding-inline-start: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: ${({ transitionDuration }) =>
    `padding-inline ${transitionDuration}ms ease-in-out`};

  ${({ isHovered, isCollapsed, collapsedWidth }) =>
    isCollapsed &&
    !isHovered &&
    `padding-inline: calc((${collapsedWidth}px - 1px - 22px) / 2);`}
`;

const NavHeader = ({ children }: PropsWithChildren) => {
  // Hooks
  const { isHovered, isCollapsed, collapsedWidth, transitionDuration } =
    useVerticalNav();

  return (
    <StyledNavHeader
      className={verticalNavClasses.header}
      isHovered={isHovered}
      isCollapsed={isCollapsed}
      collapsedWidth={collapsedWidth}
      transitionDuration={transitionDuration}
    >
      {children}
    </StyledNavHeader>
  );
};

export default NavHeader;
