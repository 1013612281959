import { styled } from '@mui/material';

import type { VerticalNavProps } from '../../components/vertical-menu/VerticalNav';
import type { VerticalNavState } from '../../contexts/verticalNavContext';
import {
  horizontalNavClasses,
  menuClasses,
  verticalNavClasses,
} from '../../utils/menuClasses';

type StyledVerticalNavProps = VerticalNavProps &
  Pick<VerticalNavState, 'collapsing' | 'transitionDuration'>;

const StyledVerticalNav = styled('aside')<StyledVerticalNavProps>`
  ${() =>
    `
    position: sticky;
    inset-block-start: 0;
    block-size: 100dvh;
  `}
  z-index: 9;

  /* Transition */
  transition-property: inline-size, min-inline-size, margin-inline-start,
    inset-inline-start;
  transition-duration: ${({ transitionDuration }) => `${transitionDuration}ms`};
  transition-timing-function: ease-in-out;

  /* Width & Min Width & Margin */
  inline-size: ${({ width }) => `${width}px`};
  min-inline-size: ${({ width }) => `${width}px`};
  &.${verticalNavClasses.collapsed} {
    inline-size: ${({ collapsedWidth }) => `${collapsedWidth}px`};
    min-inline-size: ${({ collapsedWidth }) => `${collapsedWidth}px`};
  }

  &.${verticalNavClasses.collapsing} {
    pointer-events: none;
  }

  &.${horizontalNavClasses.root} .${menuClasses.root} > ul {
    flex-direction: column;
    align-items: stretch;
  }

  /* User Styles */
  ${({ customStyles }) => customStyles}
`;

export default StyledVerticalNav;
