import { Box, useTheme } from '@mui/material';

import menuItemStyles from '@core/styles/vertical/menuItemStyles';
import menuSectionStyles from '@core/styles/vertical/menuSectionStyles';
import useVerticalNav from '@menu/hooks/useVerticalNav';
import { Menu, MenuItem } from '@menu/vertical-menu';

import { useTranslation } from '@/i18n/client';

import UserDropdown from '../shared/UserDropdown';

const VerticalMenu = () => {
  // Hooks
  const theme = useTheme();
  const verticalNavOptions = useVerticalNav();
  const { t } = useTranslation();

  const items = [
    <MenuItem
      key='home'
      href={`/home`}
      icon={<i className='material-symbols-team-dashboard-outline' />}
    >
      {t('navigation.dashboard')}
    </MenuItem>,
    <MenuItem
      key='inventory'
      data-testid='/inventory'
      href={`/inventory`}
      icon={<i className='material-symbols-inventory-2-outline' />}
    >
      {t('navigation.inventory')}
    </MenuItem>,
    <MenuItem
      key='search'
      href={`/search`}
      icon={<i className='material-symbols-search' />}
    >
      {t('navigation.search')}
    </MenuItem>,
    <MenuItem
      key='jobs'
      href={`/jobs/backup`}
      icon={<i className='material-symbols-settings-backup-restore-rounded' />}
    >
      {t('navigation.restore')}
    </MenuItem>,
    <MenuItem
      key='audit-logs'
      href={`/audit-logs`}
      icon={<i className='tabler-logs' />}
    >
      {t('navigation.auditLogs')}
    </MenuItem>,
  ].filter(Boolean);

  return (
    <div className='flex flex-col flex-grow'>
      <Menu
        data-testid='app-vertical-menu'
        popoutMenuOffset={{ mainAxis: 10 }}
        menuItemStyles={menuItemStyles(verticalNavOptions, theme)}
        menuSectionStyles={menuSectionStyles(verticalNavOptions, theme)}
      >
        {items}
        <Box
          sx={{ height: `calc(100vh - ${60 * (items.length + 3)}px)` }}
        ></Box>
        <MenuItem
          href={`/settings`}
          icon={<i className='material-symbols-settings-outline' />}
        >
          {t('navigation.settings')}
        </MenuItem>
        <UserDropdown />
      </Menu>
    </div>
  );
};

export default VerticalMenu;
