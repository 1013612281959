import { Box, Button, List, ListItemText, Typography } from '@mui/material';
import { useState } from 'react';

import { Loader } from '@/components/layout/loading';
import { SnapshotCalendar } from '@/components/snapshots/snapshotCalendar';
import VaultTag from '@/components/vaults/VaultTag';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';
import type { BackupVault } from '@/data/vaults/backupVault';
import useBackupVaults from '@/data/vaults/useBackupVaults';
import { dayjs } from '@/utils/dayjs';

import { StepContainer } from '../../wizard/StepContainer';

export const SelectSnapshot = <T,>(
  props: StepProps<
    T & {
      actionType: string;
      resourceIdForSnapshots?: string;
    }
  >
) => {
  const [view] = useState('list');
  const dal = useDAL();
  const { body: snapshotsData } = dal.inventory.snapshots.get(
    props.state.resourceIdForSnapshots!,
    'eon',
    0,
    100
  );

  const { data: vaultsBody } = useBackupVaults();

  if (!snapshotsData?.data || !vaultsBody?.length) {
    return <Loader />;
  }

  const usedVaults = vaultsBody.reduce<Record<string, BackupVault>>(
    (acc, v) => {
      if (snapshotsData.data?.some((x) => x.vaultId === v.id)) {
        acc[v.id] = v;
      }
      return acc;
    },
    {}
  );

  return (
    <StepContainer
      hideNavigation
      stepperLabels={props.stepperLabels}
      stepperIndex={0}
    >
      <Typography variant='h5'>Select Point in Time</Typography>
      <Typography component={'span'}>
        {`${snapshotsData.data.filter((x) => x.status === 'COMPLETED').length} points in time were found, located in ${Object.keys(usedVaults).length} vaults:`}
      </Typography>
      {Object.values(usedVaults).map((vault) => (
        <VaultTag key={vault.id} vault={vault} />
      ))}

      {view === 'list' && (
        <List className='mt-[20px]'>
          {snapshotsData.data
            .filter((x) => x.status === 'COMPLETED')
            .map((snapshot) => (
              <ListItemText
                className='py-[8px]'
                key={snapshot.id}
                primary={
                  <div className='flex items-center'>
                    <Box
                      className='w-[16px] h-[16px] rounded-xl mr-5'
                      sx={{
                        backgroundColor:
                          usedVaults[snapshot.vaultId].backgroundColor,
                      }}
                    />
                    <div className='flex flex-grow'>
                      <Typography className='flex-1'>{snapshot.id}</Typography>
                      <Typography className='flex-1'>
                        {dayjs(snapshot.dateCreated).calendar()}
                      </Typography>
                    </div>
                    <Button
                      size='small'
                      variant='outlined'
                      onClick={() => {
                        props.setState((state) => ({
                          ...state,
                          snapshotId: snapshot.id,
                        }));
                        props.setNextStep(props.currentStep.next?.[0]);
                      }}
                    >
                      {props.state.actionType === 'snapshot'
                        ? 'Convert to EBS Snapshot'
                        : props.state.actionType === 'volume'
                          ? 'Restore Volume'
                          : props.state.actionType === 'file'
                            ? 'Restore File'
                            : 'Next'}
                    </Button>
                  </div>
                }
              />
            ))}
        </List>
      )}

      {view === 'calendar' && (
        <SnapshotCalendar
          toSelect='snapshot'
          onDateRangeChanged={() => {}}
          vaults={vaultsBody}
          onSelected={(snapshot) => {
            props.setState((state) => ({
              ...state,
              snapshotId: snapshot.snapshotId,
            }));
            props.setNextStep(props.currentStep.next?.[0]);
          }}
          data={snapshotsData.data
            .filter((x) => x.status === 'COMPLETED')
            .map((x) => ({
              vaultId: x.vaultId,
              vaultName: x.vaultId,
              snapshotId: x.id,
              generatedOn: x.dateCreated,
              snapshot: x,
            }))}
        />
      )}
    </StepContainer>
  );
};
