import { Box, Stack, Typography } from '@mui/material';
import type { BackupPolicy } from '@repo/api-gw-sdk';

import BackupPolicyCard from '@/app/(dashboard)/settings/backup-policies/BackupPolicyCard';
import useBackupVaults from '@/data/vaults/useBackupVaults';

export const BackupPoliciesTab = ({
  activePolicies,
}: {
  activePolicies: BackupPolicy[];
}) => {
  const { data: vaults } = useBackupVaults();

  return (
    <Box className='px-[40px] mt-[40px]'>
      <Stack className='pb-[20px]' gap={'12px'}>
        <Typography fontSize={'20px'}>
          {`${activePolicies.length} Active Backup Policies`}
        </Typography>
        <Typography variant='body2'>
          View which conditions cause the resource to be backed up, and what
          backup policies are running on it.
        </Typography>
      </Stack>
      {activePolicies.map((policy) => (
        <Box key={policy.id} className='mb-[12px]'>
          <BackupPolicyCard
            policy={policy}
            vaults={vaults}
            defaultExpanded={activePolicies.length === 1}
          />
        </Box>
      ))}
    </Box>
  );
};
