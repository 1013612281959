export const PropertyGroups: Record<string, { title: string }> = {
  primary: {
    title: 'Properties',
  },
  'backup-details': {
    title: 'Backup Details',
  },
  'source-location': {
    title: 'Source Location',
  },
};
