import type { CSSObject } from '@mui/material';
import { isValidElement } from 'react';
import type { PropsWithChildren, ReactElement, ReactNode } from 'react';

import { menuClasses } from './menuClasses';

import StyledMenuIcon from '../styles/StyledMenuIcon';
import type { RenderExpandedMenuItemIcon } from '../types';

interface RenderMenuIconParams {
  level?: number;
  active?: boolean;
  disabled?: boolean;
  styles?: CSSObject;
  icon?: ReactElement;
  renderExpandedMenuItemIcon?: RenderExpandedMenuItemIcon;
  isBreakpointReached?: boolean;
}

export const confirmUrlInChildren = (
  children: PropsWithChildren['children'],
  url: string
): boolean => {
  if (!children) {
    return false;
  }

  if (Array.isArray(children)) {
    return children.some((child: ReactNode) =>
      confirmUrlInChildren(child, url)
    );
  }

  if (isValidElement(children)) {
    const { component, href, children: subChildren } = children.props;

    if (component?.props.href) {
      return component.props.href === url;
    }

    if (href) {
      return href === url;
    }

    if (subChildren) {
      return confirmUrlInChildren(subChildren, url);
    }
  }

  return false;
};

/*
 * Render all the icons for Menu Item and SubMenu components for all the levels more than 0
 */
export const renderMenuIcon = (params: RenderMenuIconParams) => {
  const {
    icon,
    level,
    active,
    disabled,
    styles,
    renderExpandedMenuItemIcon,
    isBreakpointReached,
  } = params;

  if (icon && (level === 0 || (!isBreakpointReached && level && level > 0))) {
    return (
      <StyledMenuIcon className={menuClasses.icon} rootStyles={styles}>
        {icon}
      </StyledMenuIcon>
    );
  }

  if (
    level &&
    level !== 0 &&
    renderExpandedMenuItemIcon?.icon !== null &&
    (!renderExpandedMenuItemIcon?.level ||
      renderExpandedMenuItemIcon.level >= level)
  ) {
    const iconToRender =
      typeof renderExpandedMenuItemIcon?.icon === 'function'
        ? renderExpandedMenuItemIcon.icon({ level, active, disabled })
        : renderExpandedMenuItemIcon?.icon;

    if (iconToRender) {
      return (
        <StyledMenuIcon className={menuClasses.icon} rootStyles={styles}>
          {iconToRender}
        </StyledMenuIcon>
      );
    }
  }

  return null;
};
