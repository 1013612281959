import { styled, Table } from '@mui/material';

export const SidePanelPropsTable = styled(Table)({
  '&': {
    width: '100%',
  },
  '& td': {
    padding: '5px 0',
  },
  '& .props-col': {
    width: '170px',
    fontWeight: '500',
    verticalAlign: 'top',
  },
});
