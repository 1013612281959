// Third-party Imports
import { styled } from '@mui/material';

// Type Imports
import type { RootStylesType } from '../types';

type StyledMenuLabelProps = RootStylesType & {
  textTruncate?: boolean;
};

const StyledMenuLabel = styled('span')<StyledMenuLabelProps>`
  flex-grow: 1;
  ${({ textTruncate }) =>
    textTruncate &&
    `
      text-overflow: ellipsis;
      overflow: visible;
      white-space: nowrap;
    `};
  ${({ rootStyles }) => rootStyles};
`;

export default StyledMenuLabel;
