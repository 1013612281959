import type { Theme } from '@mui/material';

import type { VerticalNavState } from '@menu/contexts/verticalNavContext';
import type { MenuItemStyles } from '@menu/types';
import { menuClasses } from '@menu/utils/menuClasses';

const menuItemStyles = (
  verticalNavOptions: VerticalNavState,
  theme: Theme
): MenuItemStyles => {
  // Vars
  const { isCollapsed, isHovered, collapsedWidth, transitionDuration } =
    verticalNavOptions;

  const collapsedNotHovered = isCollapsed && !isHovered;

  return {
    root: () => ({
      [`&.${menuClasses.subMenuRoot}.${menuClasses.open} > .${menuClasses.button}, &.${menuClasses.subMenuRoot} > .${menuClasses.button}.${menuClasses.active}`]:
        {
          backgroundColor: 'var(--mui-palette-action-selected) !important',
        },
      [`&.${menuClasses.disabled} > .${menuClasses.button}`]: {
        color: 'var(--mui-palette-text-disabled)',
      },
      [`&:not(.${menuClasses.subMenuRoot}) > .${menuClasses.button}.${menuClasses.active}`]:
        {
          background: 'var(--mui-palette-common-white)',
          [`& .${menuClasses.icon}`]: {
            color: 'var(--mui-palette-background-dark)',
          },
        },
    }),
    button: ({ active }) => ({
      paddingBlock: theme.spacing(2),
      ...(!(isCollapsed && !isHovered) && {
        '&:has(.MuiChip-root)': {
          paddingBlock: theme.spacing(1.75),
        },
      }),
      transition: `padding-inline-start ${transitionDuration}ms ease-in-out`,
      paddingInlineStart: theme.spacing(
        collapsedNotHovered ? (collapsedWidth! - 25) / 8 : 5.5
      ),
      paddingInlineEnd: theme.spacing(
        collapsedNotHovered ? (collapsedWidth! - 25) / 8 - 1.25 : 3.5
      ),
      ...(!active && {
        '&:hover, &:focus-visible': {
          backgroundColor: 'var(--mui-palette-action-hover)',
        },
        '&[aria-expanded="true"]': {
          backgroundColor: 'var(--mui-palette-action-selected)',
        },
      }),
    }),
    icon: ({ level }) => ({
      transition: `margin-right ${transitionDuration}ms ease-in-out`,
      ...(level === 0 && {
        fontSize: '1.375rem',
      }),
      ...(level > 0 && {
        fontSize: '0.75rem',
        color: 'var(--mui-palette-text-secondary)',
      }),
      '& > i, & > svg': {
        fontSize: 'inherit',
      },
    }),
    prefix: {
      marginInlineEnd: theme.spacing(2),
    },
    label: () => ({
      transition: `opacity ${transitionDuration}ms ease-in-out`,
      ...(collapsedNotHovered && {
        opacity: 0,
      }),
    }),
    suffix: {
      marginInlineStart: theme.spacing(2),
    },
    subMenuExpandIcon: {
      fontSize: '1.375rem',
      marginInlineStart: theme.spacing(2),
      '& i, & svg': {
        fontSize: 'inherit',
      },
    },
    subMenuContent: () => ({
      zIndex: 'calc(var(--drawer-z-index) + 1)',
      backgroundColor: 'transparent',
    }),
  };
};

export default menuItemStyles;
