import { Box } from '@mui/material';
import type { BackupVault } from '@repo/api-gw-sdk';
import { useState } from 'react';

import { SnapshotCalendar } from '@/components/snapshots/snapshotCalendar';
import { useWorkspace } from '@/contexts/useWorkspace';
import { useDAL } from '@/data/dal';
import useBackupVaults from '@/data/vaults/useBackupVaults';

import { Panels } from '../../panels';

export const PointsInTimeTab = (props: {
  entityId: string;
  vaults: BackupVault[];
}) => {
  const dal = useDAL();
  const now = new Date();
  const [, setDateRange] = useState<[Date, Date]>([
    new Date(now.getFullYear(), now.getMonth(), 1),
    new Date(now.getFullYear(), now.getMonth(), now.getDate()),
  ]);

  const { data: vaults } = useBackupVaults();
  const { body } = dal.inventory.snapshots.get(props.entityId, 'eon', 0, 100);
  const { rightPanel } = useWorkspace();
  const { setComponent } = rightPanel;
  return (
    <Box sx={{ margin: '24px auto 0' }}>
      <SnapshotCalendar
        toSelect='volume'
        onDateRangeChanged={(start, end) => setDateRange([start, end])}
        vaults={vaults.filter((x) => props.vaults.some((y) => y.id === x.id))}
        data={body?.data.map((x) => ({
          vaultId: x.vaultId,
          snapshot: x,
          snapshotId: x.id,
          generatedOn: x.dateCreated,
        }))}
        onSelected={(snap, vault, volume) => {
          if (volume) {
            setComponent({
              panel: Panels.RestoreVolumeWizard,
              props: {
                instanceId: props.entityId,
                volumeId: volume.volumeId,
                volumeRegion: volume.region,
                snapshotId: snap.snapshotId,
                actionType: 'volume',
              },
            });
          }
        }}
      />
    </Box>
  );
};
