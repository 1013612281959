import {
  Box,
  Button,
  CardContent,
  FormControl,
  FormLabel,
  TextField,
} from '@mui/material';
import type { User } from '@repo/api-gw-sdk';
import { useEffect, useState } from 'react';

import { useRoles } from '@/contexts/useRoles';
import { useUser } from '@/contexts/useUser';
import { useWorkspace } from '@/contexts/useWorkspace';
import { useDAL } from '@/data/dal';

import { SidePanelWrapper } from '../layout/sidePanel/sidePanelWrapper';
import {
  ProjectRolesComponent,
  RolesComponent,
} from '../permissions/projectRolesComponent';
import { TransactionalTextField } from '../shared/transactionalTextField';

export const UserPanel = ({
  currentProject,
  initialUser,
  onSave,
  onDelete,
}: {
  currentProject: boolean;
  initialUser: User;
  onSave: (user: User) => void;
  onDelete?: () => void;
}) => {
  const { isAuthorized } = useRoles();
  const { currentProjectId } = useUser();
  const dal = useDAL();
  const [updatedUser, setUpdatedUser] = useState({ ...initialUser });
  const { rightPanel } = useWorkspace();
  const { setIsOpen } = rightPanel;
  const [emailError, setEmailError] = useState(false);
  const close = () => setIsOpen(false);

  useEffect(() => {
    if (initialUser.id !== updatedUser.id) {
      setUpdatedUser(initialUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUser]);

  const checkedRoles = Object.keys(updatedUser.roleToProjectsMappings).filter(
    (x) =>
      currentProject
        ? updatedUser.roleToProjectsMappings[x]?.ids?.includes(
            currentProjectId!
          )
        : updatedUser.roleToProjectsMappings[x]
  );

  return (
    <SidePanelWrapper
      title={updatedUser.id ? 'Edit User' : 'Create User'}
      close={close}
    >
      <CardContent>
        <Box>
          <FormControl size='small' className='w-full'>
            <FormLabel>Name</FormLabel>
            <TransactionalTextField
              initValue={updatedUser.givenName}
              onChange={(value) =>
                setUpdatedUser({
                  ...updatedUser,
                  givenName: value,
                })
              }
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl size='small' className='w-full mt-[24px]'>
            <FormLabel>Email</FormLabel>
            <TextField
              disabled={!!updatedUser.id}
              size='small'
              error={emailError}
              helperText={emailError ? 'Invalid email' : ''}
              onChange={(event) => {
                setUpdatedUser({
                  ...updatedUser,
                  email: event.target.value,
                });
                setEmailError(!event.target.validity.valid);
              }}
              inputProps={{
                type: 'email',
              }}
              value={updatedUser.email}
            />
          </FormControl>
        </Box>
        {!currentProject && (
          <Box className='w-full mt-[24px]'>
            <FormLabel>Roles</FormLabel>
            <ProjectRolesComponent
              roleToProjectsMappings={updatedUser.roleToProjectsMappings}
              onChange={(roleToProjectsMappings) =>
                setUpdatedUser({ ...updatedUser, roleToProjectsMappings })
              }
            />
          </Box>
        )}
        {currentProject && (
          <Box className='w-full mt-[24px]'>
            <FormLabel>Roles</FormLabel>
            <RolesComponent
              projectId={currentProjectId!}
              roleToProjectsMappings={updatedUser.roleToProjectsMappings}
              onChange={(roleToProjectsMappings) =>
                setUpdatedUser({ ...updatedUser, roleToProjectsMappings })
              }
            />
          </Box>
        )}
        {updatedUser.id && isAuthorized('delete:users') && (
          <Button
            variant='outlined'
            className='mt-[24px] mr-[8px]'
            onClick={() => {
              if (currentProject) {
                const roleToProjectsMappings = Object.entries(
                  updatedUser.roleToProjectsMappings
                ).reduce<User['roleToProjectsMappings']>((agg, entry) => {
                  const updatedProjects =
                    entry[1].ids?.filter((x) => x !== currentProjectId) || [];
                  if (updatedProjects.length) {
                    agg[entry[0]] = { ids: updatedProjects };
                  }
                  return agg;
                }, {});

                void dal.users
                  .update(updatedUser.id, {
                    ...updatedUser,
                    roleToProjectsMappings,
                  })
                  .then(() => onDelete?.());
              } else {
                void dal.users.delete(updatedUser.id).then(() => onDelete?.());
              }
            }}
          >
            Delete User
          </Button>
        )}
        {isAuthorized('update:users') && (
          <Button
            variant='contained'
            className='mt-[24px]'
            disabled={
              emailError ||
              !updatedUser.givenName ||
              !updatedUser.email ||
              checkedRoles.length === 0
            }
            onClick={() => {
              if (updatedUser.id) {
                void dal.users
                  .update(updatedUser.id, updatedUser)
                  .then((newUser) => onSave(newUser));
              } else {
                void dal.users
                  .create({
                    ...updatedUser,
                    email: updatedUser.email!,
                  })
                  .then((newUser) => onSave(newUser));
              }
            }}
          >
            Save
          </Button>
        )}
      </CardContent>
    </SidePanelWrapper>
  );
};
