import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';

import { useDAL } from '@/data/dal';

export const AvailabilityZoneSelect = ({
  accountId,
  regionName,
  availabilityZone,
  onChange,
}: {
  accountId?: string;
  regionName?: string;
  availabilityZone?: string;
  onChange: (availabilityZone: string) => void;
}) => {
  if (!accountId || !regionName) {
    return <EmptyAvailabilityZoneSelect />;
  }

  return (
    <InnerAvailabilityZoneSelect
      accountId={accountId}
      regionName={regionName}
      availabilityZone={availabilityZone}
      onChange={onChange}
    />
  );
};

const InnerAvailabilityZoneSelect = ({
  accountId,
  regionName,
  availabilityZone,
  onChange,
}: {
  accountId: string;
  regionName: string;
  availabilityZone?: string;
  onChange: (availabilityZone: string) => void;
}) => {
  const dal = useDAL();
  const { body } = dal.restore.availabilityZones.get(accountId, regionName);

  if (!body) {
    return <EmptyAvailabilityZoneSelect />;
  }

  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>Availability Zone</FormLabel>
      <Select
        size='small'
        value={availabilityZone || ''}
        onChange={(event) => onChange(event.target.value)}
      >
        {body.availabilityZones.map((availabilityZone) => (
          <MenuItem key={availabilityZone} value={availabilityZone}>
            {availabilityZone}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const EmptyAvailabilityZoneSelect = () => {
  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>Availability Zone</FormLabel>
      <Select size='small' disabled value='' />
    </FormControl>
  );
};
