import {
  Box,
  Button,
  CardContent,
  FormControl,
  FormLabel,
  capitalize,
  Divider,
  TextField,
} from '@mui/material';

import { Loader } from '@/components/layout/loading';
import { SidePanelWrapper } from '@/components/layout/sidePanel/sidePanelWrapper';
import { useWorkspace } from '@/contexts/useWorkspace';

import { useCreateAccount, type AccountType } from './useCreateAccount';

export const CreateAccountPanel = ({
  accountType,
  onCreated,
}: {
  accountType: AccountType;
  onCreated: () => void;
}) => {
  const { rightPanel } = useWorkspace();
  const { setIsOpen } = rightPanel;
  const close = () => setIsOpen(false);

  const {
    accountId,
    setAccountId,
    roleName,
    setRoleName,
    isPopupOpen,
    isAccountCreated,
    isCreatingAccount,
    hasError,
    retry,
    createAccount,
    openPopup,
  } = useCreateAccount(accountType, onCreated);

  return (
    <SidePanelWrapper
      title={`Connect ${capitalize(accountType)} Account`}
      close={close}
    >
      <CardContent>
        <Box className='px-[20px]'>
          <Box className='my-[15px]'>
            Before we begin,{' '}
            <b>
              make sure you are logged in to that account in the current browser
              and can create roles
            </b>
          </Box>
          <Box className='my-[15px]'>
            We&apos;ll take you straight to the AWS console to set Eon&apos;s
            permissions. Everything is ready for you, just jump between the
            steps, confirm, and get back to us.
          </Box>
          <Box className='flex flex-col justify-center my-[30px] gap-[8px]'>
            <FormControl size='small'>
              <FormLabel>AWS Account ID</FormLabel>
              <TextField
                size='medium'
                inputProps={{
                  style: { height: '36px' },
                  maxLength: 12,
                  pattern: '[0-9]*',
                  'data-testid': 'account-id',
                }}
                disabled={isPopupOpen || isCreatingAccount || hasError}
                value={accountId}
                onChange={(event) => setAccountId(event.target.value)}
                onKeyDown={(event) => event.key === 'Enter' && void openPopup()}
              />
            </FormControl>
            <FormControl size='small'>
              <FormLabel>Role Name</FormLabel>
              <TextField
                size='medium'
                inputProps={{ style: { height: '36px' } }}
                value={roleName}
                disabled={isPopupOpen || isCreatingAccount || hasError}
                onChange={(event) => setRoleName(event.target.value)}
                onKeyDown={(event) => event.key === 'Enter' && void openPopup()}
              />
            </FormControl>
            {!isPopupOpen &&
              !isCreatingAccount &&
              !isAccountCreated &&
              !hasError && (
                <Button
                  variant='contained'
                  className='px-[20px] mt-[20px] w-auto'
                  sx={{ height: 48 }}
                  data-testid='open-aws-console'
                  disabled={!accountId || !roleName}
                  onClick={() => void openPopup()}
                >
                  Open AWS Console
                  <i className='material-symbols-open-in-new w-[20px] h-[20px] ml-[8px]' />
                </Button>
              )}
            {(isPopupOpen || isCreatingAccount) && (
              <Box
                className='flex items-center gap-[8px] ml-[20px]'
                sx={{ color: 'var(--mui-palette-background-lightGray)' }}
              >
                <Loader sx={{ width: 20 }} />
                {isPopupOpen && <>Waiting for permissions...</>}
                {isCreatingAccount && <>Trying to connect...</>}
                {!isCreatingAccount && (
                  <>
                    <Divider
                      orientation='vertical'
                      flexItem
                      sx={{
                        borderColor: 'var(--mui-palette-background-lightGray)',
                      }}
                      className='mx-[4px] my-auto h-[20px]'
                    />
                    <Button
                      variant='contained'
                      data-testid='aws-console-done'
                      onClick={() => void createAccount()}
                    >
                      I&apos;m Done
                    </Button>
                  </>
                )}
              </Box>
            )}
            {isAccountCreated && (
              <Box
                className='flex items-center gap-[4px] ml-[20px] h-[48px]'
                sx={{ color: 'var(--mui-palette-success-main)' }}
              >
                <i className='material-symbols-check w-[20px] h-[20px]' />{' '}
                Account Created
              </Box>
            )}
            {hasError && (
              <Box
                className='flex items-center gap-[4px] ml-[20px] h-[48px]'
                sx={{ color: 'var(--mui-palette-error-main)' }}
              >
                <i className='material-symbols-close-rounded w-[20px] h-[20px]' />{' '}
                Something went wrong
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{
                    borderColor: 'var(--mui-palette-background-lightGray)',
                  }}
                  className='mx-[6px] my-auto h-[20px]'
                />
                <Button variant='contained' onClick={retry}>
                  Try Again
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </CardContent>
    </SidePanelWrapper>
  );
};
