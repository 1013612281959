import { FloatingTree } from '@floating-ui/react';
import type { CSSObject } from '@mui/material';
import classnames from 'classnames';
import { usePathname } from 'next/navigation';
import {
  createContext,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type {
  ForwardRefRenderFunction,
  MenuHTMLAttributes,
  MutableRefObject,
  ReactElement,
  ReactNode,
} from 'react';

import { verticalSubMenuToggleDuration } from '../../defaultConfigs';
import styles from '../../styles/styles.module.css';
import StyledVerticalMenu from '../../styles/vertical/StyledVerticalMenu';
import type {
  MenuItemStyles,
  RootStylesType,
  RenderExpandIconParams,
  RenderExpandedMenuItemIcon,
} from '../../types';
import { menuClasses } from '../../utils/menuClasses';

export interface MenuSectionStyles {
  root?: CSSObject;
  label?: CSSObject;
  prefix?: CSSObject;
  suffix?: CSSObject;
  icon?: CSSObject;
}

export interface OpenSubmenu {
  level: number;
  label: ReactNode;
  active: boolean;
  id: string;
}

export interface VerticalMenuContextProps {
  browserScroll?: boolean;
  triggerPopout?: 'hover' | 'click';
  transitionDuration?: number;
  menuSectionStyles?: MenuSectionStyles;
  menuItemStyles?: MenuItemStyles;
  subMenuOpenBehavior?: 'accordion' | 'collapse';
  renderExpandIcon?: (params: RenderExpandIconParams) => ReactElement;
  renderExpandedMenuItemIcon?: RenderExpandedMenuItemIcon;
  collapsedMenuSectionLabel?: ReactNode;
  popoutMenuOffset?: {
    mainAxis?: number | ((params: { level?: number }) => number);
    alignmentAxis?: number | ((params: { level?: number }) => number);
  };
  textTruncate?: boolean;

  /**
   * @ignore
   */
  openSubmenu?: OpenSubmenu[];

  /**
   * @ignore
   */
  openSubmenusRef?: MutableRefObject<OpenSubmenu[]>;

  /**
   * @ignore
   */
  toggleOpenSubmenu?: (
    ...submenus: {
      level: number;
      label: ReactNode;
      active?: boolean;
      id: string;
    }[]
  ) => void;
}

export type MenuProps = VerticalMenuContextProps &
  RootStylesType &
  MenuHTMLAttributes<HTMLMenuElement> & {
    popoutWhenCollapsed?: boolean;
  };

export const VerticalMenuContext = createContext(
  {} as VerticalMenuContextProps
);

const Menu: ForwardRefRenderFunction<HTMLMenuElement, MenuProps> = (
  props,
  ref
) => {
  // Props
  const {
    children,
    className,
    rootStyles,
    menuItemStyles,
    renderExpandIcon,
    renderExpandedMenuItemIcon,
    menuSectionStyles,
    browserScroll = false,
    triggerPopout = 'hover',
    subMenuOpenBehavior = 'accordion', // accordion, collapse
    transitionDuration = verticalSubMenuToggleDuration,
    collapsedMenuSectionLabel = '-',
    popoutMenuOffset = { mainAxis: 0 },
    textTruncate = true,
    ...rest
  } = props;

  // States
  const [openSubmenu, setOpenSubmenu] = useState<OpenSubmenu[]>([]);

  // Refs
  const openSubmenusRef = useRef<OpenSubmenu[]>([]);

  // Hooks
  const pathname = usePathname();
  const toggleOpenSubmenu = useCallback(
    (
      ...submenus: {
        level: number;
        label: ReactNode;
        active?: boolean;
        id: string;
      }[]
    ): void => {
      if (!submenus.length) return;

      const openSubmenuCopy = [...openSubmenu];

      submenus.forEach(({ level, label, active = false, id }) => {
        const submenuIndex = openSubmenuCopy.findIndex(
          (submenu) => submenu.id === id
        );
        const submenuExists = submenuIndex >= 0;
        const isAccordion = subMenuOpenBehavior === 'accordion';

        const inactiveSubmenuIndex = openSubmenuCopy.findIndex(
          (submenu) => !submenu.active && submenu.level === 0
        );

        // Delete submenu if it exists
        if (submenuExists) {
          openSubmenuCopy.splice(submenuIndex, 1);
        }

        if (isAccordion) {
          // Add submenu if it doesn't exist
          if (!submenuExists) {
            if (inactiveSubmenuIndex >= 0 && !active && level === 0) {
              openSubmenuCopy.splice(inactiveSubmenuIndex, 1, {
                level,
                label,
                active,
                id,
              });
            } else {
              openSubmenuCopy.push({ level, label, active, id });
            }
          }
        } else {
          // Add submenu if it doesn't exist
          if (!submenuExists) {
            openSubmenuCopy.push({ level, label, active, id });
          }
        }
      });

      setOpenSubmenu(openSubmenuCopy);
    },
    [openSubmenu, subMenuOpenBehavior]
  );

  useEffect(() => {
    setOpenSubmenu([...openSubmenusRef.current]);
    openSubmenusRef.current = [];
  }, [pathname]);

  const providerValue = useMemo(
    () => ({
      browserScroll,
      triggerPopout,
      transitionDuration,
      menuItemStyles,
      menuSectionStyles,
      renderExpandIcon,
      renderExpandedMenuItemIcon,
      openSubmenu,
      openSubmenusRef,
      toggleOpenSubmenu,
      subMenuOpenBehavior,
      collapsedMenuSectionLabel,
      popoutMenuOffset,
      textTruncate,
    }),
    [
      browserScroll,
      triggerPopout,
      transitionDuration,
      menuItemStyles,
      menuSectionStyles,
      renderExpandIcon,
      renderExpandedMenuItemIcon,
      openSubmenu,
      openSubmenusRef,
      toggleOpenSubmenu,
      subMenuOpenBehavior,
      collapsedMenuSectionLabel,
      popoutMenuOffset,
      textTruncate,
    ]
  );

  return (
    <VerticalMenuContext.Provider value={providerValue}>
      <FloatingTree>
        <StyledVerticalMenu
          ref={ref}
          className={classnames(menuClasses.root, className)}
          rootStyles={rootStyles}
          {...rest}
        >
          <ul className={`${styles.ul} h-full relative`}>{children}</ul>
        </StyledVerticalMenu>
      </FloatingTree>
    </VerticalMenuContext.Provider>
  );
};

export default forwardRef(Menu);
