// Third-party Imports
import { styled } from '@mui/material';

// Type Imports
import type { RootStylesType } from '../types';

const StyledMenuIcon = styled('span')<RootStylesType>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;

  ${({ rootStyles }) => rootStyles};

  i {
    width: 24px;
    height: 24px;
  }
`;

export default StyledMenuIcon;
