import {
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Typography,
  IconButton,
} from '@mui/material';
import { useRef, useState } from 'react';

import { useRoles } from '@/contexts/useRoles';
import { useUser } from '@/contexts/useUser';

export const RoleDropdown = () => {
  const { roles } = useRoles();

  const [open, setOpen] = useState(false);
  const { user, changeRole } = useUser();

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleRoleSwitch = (roleId: string) => {
    handleClose();
    changeRole(roleId);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleToggle}
        className='gap-3 py-[8px] w-full bg-transparent rounded-none justify-start'
      >
        <i className={`ri-user-3-line text-textPrimary`} />
        <Typography color='text.primary'>{user?.selectedRole.name}</Typography>
      </IconButton>
      <Popper
        open={open}
        transition
        disablePortal
        placement='bottom-start'
        anchorEl={anchorRef.current}
        className='min-is-[160px] !mbs-4 z-[1]'
      >
        {({ TransitionProps, placement }) => (
          <Fade
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'right top',
            }}
          >
            <Paper className={'shadow-lg'}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList onKeyDown={handleClose}>
                  {Object.keys(user?.roleToProjectsMappings || {}).map(
                    (roleId) => (
                      <MenuItem
                        key={roleId}
                        className='gap-3'
                        onClick={() => handleRoleSwitch(roleId)}
                        selected={user?.selectedRole.id === roleId}
                        value={roleId}
                      >
                        {roles.find((x) => x.id === roleId)?.name}
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};
