import { CreateAccountInputCloudProviderEnum } from '@repo/api-gw-sdk';
import { useState } from 'react';

import { AddNewAccountComponent } from '@/components/accounts/addNewAccountComponent';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';

import { StepContainer } from '../../wizard/StepContainer';

export const AddNewAccount = <T,>(
  props: StepProps<T & { restoreAccountId?: string }>
) => {
  const [accountParams, setAccountParams] = useState<
    | {
        accountNumber: string;
        roleName: string;
        roleArn: string;
        accountName: string;
      }
    | undefined
  >();
  const dal = useDAL();

  return (
    <StepContainer
      stepperLabels={props.stepperLabels}
      stepperIndex={1}
      onBackClick={props.back}
      canGoNext={() =>
        !!(
          accountParams?.accountNumber &&
          accountParams?.roleName &&
          accountParams?.accountName
        )
      }
      onNextClick={() => {
        void dal.restore.accounts
          .create({
            role: accountParams!.roleArn,
            name: accountParams!.accountName,
            cloudProvider: CreateAccountInputCloudProviderEnum.Aws,
          })
          .then((account) => {
            props.setState((state) => ({
              ...state,
              restoreAccountId: account.id,
            }));
            props.back();
          });
      }}
    >
      <AddNewAccountComponent type='restore' onChange={setAccountParams} />
    </StepContainer>
  );
};
