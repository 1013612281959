import {
  Box,
  Button,
  CardContent,
  FormControl,
  FormLabel,
  List,
  ListItemButton,
  Stack,
  Link,
  Typography,
} from '@mui/material';
import type { Project, Account } from '@repo/api-gw-sdk';
import { CreateAccountInputCloudProviderEnum } from '@repo/api-gw-sdk';
import { useEffect, useState } from 'react';

import { useRoles } from '@/contexts/useRoles';
import { useWorkspace } from '@/contexts/useWorkspace';
import { useDAL } from '@/data/dal';

import { AddNewAccountComponent } from '../accounts/addNewAccountComponent';
import { SidePanelWrapper } from '../layout/sidePanel/sidePanelWrapper';
import { TransactionalTextField } from '../shared/transactionalTextField';

export const ProjectPanel = ({
  project,
  accounts,
  onSave,
}: {
  project: Project;
  accounts: Account[];
  onSave: (project: Project) => void;
}) => {
  const { isAuthorized } = useRoles();
  const dal = useDAL();
  const [updatedProject, setUpdatedProject] = useState(project);
  const [updatedAccounts, setUpdatedAccounts] = useState(accounts);
  const [addNewAccount, setAddNewAccount] = useState(false);
  const { rightPanel } = useWorkspace();
  const { setIsOpen } = rightPanel;
  const close = () => setIsOpen(false);

  useEffect(() => {
    if (project.id !== updatedProject.id) {
      setUpdatedProject(project);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return (
    <SidePanelWrapper
      title={updatedProject.id ? 'Edit Project' : 'Create Project'}
      close={close}
    >
      <CardContent>
        {!addNewAccount && (
          <>
            <FormControl size='small' className='w-1/2 mb-[24px]'>
              <FormLabel>Project Name</FormLabel>
              <TransactionalTextField
                initValue={updatedProject.name}
                onChange={(value) =>
                  setUpdatedProject({
                    ...updatedProject,
                    name: value,
                  })
                }
              />
            </FormControl>
            <Box>
              <Box className='w-full mt-[24px]'>
                <Stack
                  className='w-1/2'
                  direction={'row'}
                  justifyContent={'space-between'}
                >
                  <FormLabel className='font-medium underline'>
                    Accounts
                  </FormLabel>
                  {isAuthorized('create:source_accounts') && (
                    <Link
                      className='cursor-pointer'
                      onClick={() => setAddNewAccount(true)}
                    >
                      Add Account
                    </Link>
                  )}
                </Stack>
                <List className='mt-[20px] mb-[10px] w-1/2'>
                  {updatedAccounts.map((account) => (
                    <ListItemButton
                      key={account.id}
                      sx={{
                        border: '2px solid var(--mui-palette-primary-main)',
                        marginBottom: '8px',
                      }}
                      className='flex cursor-default'
                    >
                      <Typography className='flex-grow'>
                        {account.name}
                      </Typography>
                      <Typography component={'span'}>
                        {account.providerAccountId}
                      </Typography>
                    </ListItemButton>
                  ))}
                </List>
              </Box>
              {isAuthorized('update:projects') && (
                <Button
                  className='mt-[24px]'
                  variant='outlined'
                  onClick={() => {
                    if (updatedProject.id) {
                      void dal.projects
                        .update(updatedProject)
                        .then((u) => onSave(u));
                      onSave(updatedProject);
                    } else {
                      void dal.projects
                        .create(updatedProject)
                        .then((u) => onSave(u));
                    }
                  }}
                >
                  Save
                </Button>
              )}
            </Box>
          </>
        )}
        {addNewAccount && (
          <AddNewAccount
            onBack={() => setAddNewAccount(false)}
            onSave={(account) => {
              setUpdatedAccounts([...updatedAccounts, account]);
              setAddNewAccount(false);
            }}
          />
        )}
      </CardContent>
    </SidePanelWrapper>
  );
};

const AddNewAccount = ({
  onBack,
  onSave,
}: {
  onBack: () => void;
  onSave: (account: Account) => void;
}) => {
  const dal = useDAL();
  const [accountParams, setAccountParams] = useState<
    | {
        accountNumber: string;
        roleName: string;
        roleArn: string;
        accountName: string;
      }
    | undefined
  >();

  return (
    <Box>
      <AddNewAccountComponent type='source' onChange={setAccountParams} />
      <Button
        variant='outlined'
        className='mt-[24px]'
        disabled={
          !(
            accountParams?.accountNumber &&
            accountParams?.roleName &&
            accountParams?.accountName
          )
        }
        onClick={() => {
          void dal.cloudAccounts.source
            .create({
              role: accountParams!.roleArn,
              name: accountParams!.accountName,
              cloudProvider: CreateAccountInputCloudProviderEnum.Aws,
            })
            .then(onSave);
        }}
      >
        Save
      </Button>
      <Button
        variant='outlined'
        className='mt-[24px] ml-[8px]'
        onClick={onBack}
      >
        Back
      </Button>
    </Box>
  );
};
