import {
  ClickAwayListener,
  Typography,
  Fade,
  Paper,
  Popper,
  Divider,
  MenuItem,
  MenuList,
  Button,
  Avatar,
  IconButton,
} from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import type { MouseEvent } from 'react';
import { useEffect, useRef, useState } from 'react';

import { MenuItem as NavBarMenuItem } from '@menu/vertical-menu';

import useVerticalNav from '@/@menu/hooks/useVerticalNav';
import { ProjectDropdown } from '@/components/permissions/projectDropdown';
import { RoleDropdown } from '@/components/permissions/roleDropdown';
import { Icon } from '@/components/shared/icon';
import { useUser } from '@/contexts/useUser';

import ModeDropdown from './ModeDropdown';

const UserAvatar = () => {
  const { user } = useUser();

  return (
    <Avatar className='cursor-pointer' alt={user?.givenName} src={undefined}>
      <Icon
        iconClass='material-symbols-person-outline'
        sx={{
          '& i': {
            color: 'var(--mui-palette-common-white)',
            height: '24px',
            width: '24px',
          },
        }}
      />
    </Avatar>
  );
};

const UserDropdown = () => {
  const [open, setOpen] = useState(false);
  const { user } = useUser();
  const { isHovered } = useVerticalNav();

  const anchorRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const handleDropdownOpen = () => {
    !open ? setOpen(true) : setOpen(false);
  };

  useEffect(() => {
    if (!isHovered) {
      setOpen(false);
    }
  }, [isHovered]);

  const handleDropdownClose = (
    event?: MouseEvent<HTMLLIElement> | (MouseEvent | TouchEvent),
    url?: string
  ) => {
    if (url) {
      router.push(url);
    }

    if (anchorRef.current?.contains(event?.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <NavBarMenuItem onClick={handleDropdownOpen} icon={<UserAvatar />}>
        <div
          className='flex items-center ml-[-4px]'
          tabIndex={-1}
          ref={anchorRef}
        >
          <Typography className='text-white'>{user?.givenName}</Typography>
        </div>
      </NavBarMenuItem>
      <Popper
        open={open}
        transition
        disablePortal
        anchorEl={anchorRef.current}
        className='min-is-[240px] !mbs-4 z-[1]'
      >
        {({ TransitionProps, placement }) => (
          <Fade
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-end' ? 'right top' : 'left top',
            }}
          >
            <Paper className={'shadow-lg'}>
              <ClickAwayListener
                onClickAway={(e) =>
                  handleDropdownClose(e as MouseEvent | TouchEvent)
                }
              >
                <MenuList>
                  {user?.givenName && (
                    <div
                      className='flex items-center px-[24px] py-[4px] gap-2 cursor-default'
                      tabIndex={-1}
                    >
                      <Avatar alt={user?.givenName} src={undefined} />
                      <div className='flex items-start flex-col'>
                        <Typography
                          className='font-medium'
                          color='text.primary'
                        >
                          {user?.givenName}
                        </Typography>
                        <Typography variant='caption'>{user?.email}</Typography>
                      </div>
                    </div>
                  )}
                  {user && !user.selectedRole.isProjectRole && (
                    <Link href='/global-settings'>
                      <MenuItem className='gap-3 py-0'>
                        <IconButton className='gap-3 py-[8px] w-full bg-transparent rounded-none justify-start'>
                          <i
                            className={`material-symbols-settings-outline text-textPrimary`}
                          />
                          <Typography color='text.primary'>
                            Global Settings
                          </Typography>
                        </IconButton>
                      </MenuItem>
                    </Link>
                  )}
                  <Divider className='mlb-1' />
                  <MenuItem
                    className='gap-3 py-0'
                    onClick={(e) => e.preventDefault()}
                  >
                    <ProjectDropdown />
                  </MenuItem>
                  <MenuItem
                    className='gap-3 py-0'
                    onClick={(e) => e.preventDefault()}
                  >
                    <RoleDropdown />
                  </MenuItem>
                  <Divider className='mlb-1' />
                  <MenuItem
                    className='gap-3 py-0'
                    onClick={(e) => e.preventDefault()}
                  >
                    <ModeDropdown />
                  </MenuItem>
                  <div className='flex items-center plb-2 pli-4'>
                    <Button
                      fullWidth
                      variant='contained'
                      color='error'
                      size='small'
                      endIcon={<i className='ri-logout-box-r-line' />}
                      onClick={() => router.replace('/logout')}
                      sx={{
                        '& .MuiButton-endIcon': { marginInlineStart: 1.5 },
                      }}
                    >
                      Log out
                    </Button>
                  </div>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default UserDropdown;
