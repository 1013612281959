import type { RestoreVolumeInputVolumeSettings } from '@repo/api-gw-sdk';

import { useWorkspace } from '@/contexts/useWorkspace';
import { useTranslation } from '@/i18n/client';

import { AddNewAccount } from './steps/addNewAccount';
import { SelectTargetAccount } from './steps/selectTargetAccount';
import { SnapshotConfiguration } from './steps/snapshotConfiguration';

import { Panels } from '../panels/panels';
import type { Step } from '../wizard/Step';
import { Wizard } from '../wizard/Wizard';

export interface RestoreVolumeState {
  volumeResourceId: string;
  actionType: string;
  resourceIdForSnapshots: string;
  snapshotId?: string;
  restoreAccountId?: string;
  regionName: string;
  availabilityZone?: string;
  description?: string;
  tags?: Record<string, string>;
  volumeSettings?: RestoreVolumeInputVolumeSettings;
  encryptionKeyId?: string;
}

const restoreVolumeFlow: Step<RestoreVolumeState> = {
  /*name: 'SelectSnapshotStep',
  Component: SelectSnapshot,
  next: [
    {*/
  name: 'SelectTargetAccountStep',
  Component: SelectTargetAccount,
  next: [
    {
      name: 'AddNewAccountStep',
      Component: AddNewAccount,
    },
    {
      name: 'SnapshotConfigurationStep',
      Component: SnapshotConfiguration,
    },
  ],
  //},
  //],
};

export const RestoreVolumeWizard = ({
  instanceId,
  snapshotId,
  volumeId,
  volumeRegion,
  actionType,
}: {
  instanceId: string;
  snapshotId: string;
  volumeId: string;
  volumeRegion: string;
  actionType: 'snapshot' | 'volume';
}) => {
  const { t } = useTranslation('restore');
  const { rightPanel } = useWorkspace();
  const { setComponent, setIsOpen } = rightPanel;

  const close = () => {
    setComponent({
      panel: Panels.InventoryInstance,
      props: { id: instanceId, onClose: () => setIsOpen(false) },
    });

    return true;
  };

  return (
    <Wizard<RestoreVolumeState>
      onAbort={close}
      onFinish={close}
      flow={[restoreVolumeFlow]}
      initialState={{
        volumeResourceId: volumeId,
        actionType,
        resourceIdForSnapshots: instanceId,
        regionName: volumeRegion,
        snapshotId: snapshotId,
      }}
      title={
        actionType === 'snapshot' ? t('titles.snapshot') : t('titles.volume')
      }
      stepperLabels={[
        // 'Point in Time',
        'Restore Account',
        'Configuration',
      ]}
    />
  );
};
