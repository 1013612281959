import { Button, List, ListItemButton, Radio, Typography } from '@mui/material';

import { Loader } from '@/components/layout/loading';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';

import { StepContainer } from '../../wizard/StepContainer';

export const SelectTargetAccount = <T,>(
  props: StepProps<T & { restoreAccountId?: string }>
) => {
  const dal = useDAL();
  const { body } = dal.restore.accounts.get();

  if (!body) {
    return <Loader />;
  }

  if (body.accounts.length === 0) {
    props.setNextStep(
      props.currentStep.next?.find((s) => s.name === 'AddNewAccountStep')
    );
    return <Loader />;
  }

  return (
    <StepContainer
      stepperLabels={props.stepperLabels}
      stepperIndex={1}
      onBackClick={props.back}
      canGoNext={() => !!props.state.restoreAccountId}
      onNextClick={() =>
        props.setNextStep(
          props.currentStep.next?.find((s) => s.name !== 'AddNewAccountStep')
        )
      }
    >
      <Typography variant='h5'>Set a Target Account</Typography>
      <Typography component={'span'}>
        Select from the following accounts or add a new one:
      </Typography>
      <List className='mt-[20px] mb-[10px]'>
        {body.accounts.map((account) => (
          <ListItemButton
            key={account.id}
            sx={{
              border: '2px solid var(--mui-palette-primary-main)',
              marginBottom: '8px',
            }}
            onClick={() =>
              props.setState((state) => ({
                ...state,
                restoreAccountId: account.id,
              }))
            }
            className='flex'
          >
            <Radio
              className='p-0'
              checked={props.state.restoreAccountId === account.id}
              value={account.id}
            />
            <Typography className='flex-grow'>{account.name}</Typography>
            <Typography component={'span'}>
              {account.providerAccountId}
            </Typography>
          </ListItemButton>
        ))}
      </List>

      <Button
        variant='outlined'
        size='small'
        onClick={() => {
          props.setNextStep(
            props.currentStep.next?.find((s) => s.name === 'AddNewAccountStep')
          );
        }}
      >
        <i className='material-symbols-add-rounded w-[20px] h-[20px] mr-[8px]'></i>
        Add a New Account
      </Button>
    </StepContainer>
  );
};
