import { Box } from '@mui/material';
import {
  MinRetentionRuleFrequencyEnum,
  type ControlRules,
} from '@repo/api-gw-sdk';

import { dayjs } from '@/utils/dayjs';

import { MaximumRetentionEditor } from './MaximumRetentionEditor';
import {
  MinimumRetentionEditor,
  retentionMapping,
} from './MinimumRetentionEditor';
import { NumberOfCopiesEditor } from './NumberOfCopiesEditor';

export const controlRules = [
  {
    enabled: (rules: ControlRules) => rules.numberOfCopies?.enabled,
    onToggle: (
      e: React.ChangeEvent<HTMLInputElement>,
      rules: ControlRules
    ) => ({
      numberOfCopies: {
        enabled: e.target.checked,
        minCopies: e.target.checked ? rules.numberOfCopies?.minCopies || 2 : 2,
      },
    }),
    Editor: NumberOfCopiesEditor,
    label: (rules: ControlRules, editMode?: boolean) => (
      <>
        <b>Number of copies</b> is at least
        {editMode ? '...' : <b> {rules.numberOfCopies?.minCopies || 1}</b>}
      </>
    ),
    icon: 'material-symbols-content-copy-outline',
  },
  {
    enabled: (rules: ControlRules) => rules.objectLock,
    onToggle: (e: React.ChangeEvent<HTMLInputElement>) => ({
      objectLock: e.target.checked,
    }),
    label: () => (
      <>
        All Snapshots have <b>object lock</b>
      </>
    ),
    icon: 'material-symbols-lock-outline',
  },
  {
    enabled: (rules: ControlRules) => rules.crossRegion,
    onToggle: (e: React.ChangeEvent<HTMLInputElement>) => ({
      crossRegion: e.target.checked,
    }),
    label: () => (
      <>
        At least one copy is in a <b>different region</b> from the source
      </>
    ),
    icon: 'material-symbols-globe',
  },
  {
    enabled: (rules: ControlRules) => rules.crossAccount,
    onToggle: (e: React.ChangeEvent<HTMLInputElement>) => ({
      crossAccount: e.target.checked,
    }),
    label: () => (
      <>
        At least one copy is in a <b>different account</b> from the source
      </>
    ),
    icon: 'material-symbols-person-outline',
  },
  {
    enabled: (rules: ControlRules) => rules.crossCloudProvider,
    onToggle: (e: React.ChangeEvent<HTMLInputElement>) => ({
      crossCloudProvider: e.target.checked,
    }),
    label: () => (
      <>
        At least one copy is in a <b>different cloud provider</b> from the
        source
      </>
    ),
    icon: 'material-symbols-filter-drama-outline',
  },
  {
    enabled: (rules: ControlRules) => rules.maximumRetention?.enabled,
    onToggle: (
      e: React.ChangeEvent<HTMLInputElement>,
      rules: ControlRules
    ) => ({
      maximumRetention: {
        enabled: e.target.checked,
        maximumRetention: e.target.checked
          ? rules.maximumRetention?.maximumRetention || 1
          : 1,
      },
    }),
    Editor: MaximumRetentionEditor,
    label: (rules: ControlRules, editMode?: boolean) => (
      <>
        All Snapshots have <b>maximum retention</b> of
        {editMode ? (
          '...'
        ) : (
          <b>
            {' '}
            {dayjs
              .duration({
                days: rules.maximumRetention?.maximumRetention || 1,
              })
              .humanize()}
          </b>
        )}
      </>
    ),
    icon: 'material-symbols-hourglass-bottom',
  },
  {
    enabled: (rules: ControlRules) =>
      rules.minimumRetention?.some((r) => r.enabled),
    onToggle: (e: React.ChangeEvent<HTMLInputElement>) => ({
      minimumRetention: e.target.checked
        ? [
            {
              enabled: e.target.checked,
              minimumRetention: 1,
              frequency: MinRetentionRuleFrequencyEnum.Daily,
            },
          ]
        : [],
    }),
    Editor: MinimumRetentionEditor,
    label: (rules: ControlRules, editMode?: boolean) => (
      <>
        At least one copy has a <b>minimum retention</b> of...
        {!editMode && (
          <>
            {' '}
            {rules.minimumRetention
              ?.filter((r) => r.enabled)
              .map(({ minimumRetention, frequency }, i) => {
                const { text, unit } =
                  retentionMapping.find(({ type }) => type === frequency) ||
                  retentionMapping[0];
                return (
                  <Box key={i} className='my-[8px]'>
                    <b>{text}</b> are retained for at least{' '}
                    <b>
                      {minimumRetention} {unit}
                    </b>
                  </Box>
                );
              })}
          </>
        )}
      </>
    ),
    icon: 'material-symbols-date-range',
  },
];
