import {
  Box,
  FormControl,
  FormLabel,
  Link,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { useDAL } from '@/data/dal';

import { Icon } from '../shared/icon';
import { TransactionalTextField } from '../shared/transactionalTextField';

export const EncryptionComponent = ({
  encryptionKeyId,
  accountId,
  onChange,
  regionName,
}: {
  encryptionKeyId: string | undefined;
  accountId: string;
  regionName: string;
  onChange: (encryption: Encryption) => void;
}) => {
  const [encryptionParams, setEncryptionParams] = useState<Encryption>({
    isEncrypted: true,
    encryptionKeyId,
    arn: undefined,
  });
  const dal = useDAL();
  const { isLoading, body, mutate } = dal.restore.getEncryptionKeys(
    accountId,
    regionName
  );
  useEffect(() => {
    if (body?.keys.every((x) => x.id !== encryptionParams.encryptionKeyId)) {
      const newState = {
        ...encryptionParams,
        encryptionKeyId: 'aws-default',
      };
      setEncryptionParams(newState);
      onChange(newState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body]);

  return (
    <Box className='w-full'>
      <Box className='flex justify-between items-center'>
        <Typography component={'span'} variant='h6'>
          Encrypt this resource
        </Typography>
        <Switch
          size='small'
          checked={encryptionParams.isEncrypted}
          onChange={() => {
            const newState = {
              ...encryptionParams,
              isEncrypted: !encryptionParams.isEncrypted,
            };
            setEncryptionParams(newState);
            onChange(newState);
          }}
        />
      </Box>
      {encryptionParams.isEncrypted && (
        <Box className='w-full'>
          <FormControl size='small' className='w-full mt-[20px]'>
            <FormLabel>Encryption Key</FormLabel>
            <Box className='flex items-center w-full gap-[36px]'>
              <Box className='w-1/2'>
                <Select
                  disabled={!body || isLoading}
                  size='small'
                  className='w-full'
                  value={encryptionParams.encryptionKeyId || ''}
                  onChange={(event) => {
                    const newState = {
                      ...encryptionParams,
                      encryptionKeyId: event.target.value,
                    };
                    setEncryptionParams(newState);
                    onChange(newState);
                  }}
                >
                  <MenuItem value='aws-default'>AWS Default</MenuItem>
                  {body?.keys.map((key) => (
                    <MenuItem key={key.id} value={key.arn}>
                      {key.aliasName}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box className='w-1/2 flex flex-row'>
                <Tooltip title='Refresh'>
                  <Icon
                    iconClass='material-symbols-refresh'
                    onClick={() => void mutate()}
                  />
                </Tooltip>
                {regionName && (
                  <Link
                    href={`https://${regionName}.console.aws.amazon.com/kms/home?region=${regionName}#/kms/keys/create`}
                    target='_blank'
                    className='flex items-center no-underline'
                  >
                    <Typography component={'span'}>Create New</Typography>
                    <Icon iconClass='material-symbols-open-in-new' />
                  </Link>
                )}
              </Box>
            </Box>
          </FormControl>
          <FormControl size='small' className='w-1/2 mt-[24px] pr-[18px]'>
            <FormLabel>ARN (optional)</FormLabel>
            <TransactionalTextField
              initValue={encryptionParams.arn || ''}
              onChange={(value) => {
                const newState = {
                  ...encryptionParams,
                  arn: value,
                };
                setEncryptionParams(newState);
                onChange(newState);
              }}
            />
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

interface Encryption {
  isEncrypted: boolean;
  encryptionKeyId: string | undefined;
  arn: string | undefined;
}
