import {
  Box,
  Button,
  capitalize,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { type ControlRules } from '@repo/api-gw-sdk';
import { useMemo, useRef, useState } from 'react';

import { dayjs } from '@/utils/dayjs';

const durationOpts = ['days', 'weeks', 'months', 'years'] as const;
type durationFragment = (typeof durationOpts)[number];
export function MaximumRetentionEditor({
  rules,
  onChange,
}: {
  rules: ControlRules;
  onChange: (rules: ControlRules) => void;
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const duration = useMemo(
    () =>
      dayjs.duration({
        days: rules.maximumRetention?.maximumRetention || 1,
      }),
    [rules.maximumRetention?.maximumRetention]
  );
  const [selectedFragment, setSelectedFragment] = useState(() => {
    if (duration.asYears() >= 1 && duration.asYears() % 1 === 0)
      return durationOpts[3];
    if (duration.asMonths() >= 1 && duration.asMonths() % 1 === 0)
      return durationOpts[2];
    if (duration.asWeeks() >= 1 && duration.asWeeks() % 1 === 0)
      return durationOpts[1];
    return durationOpts[0];
  });
  const durationValue = useMemo(
    () =>
      Math.round(
        duration[`as${capitalize(selectedFragment)}` as durationFragment]()
      ),
    [duration, selectedFragment]
  );

  const update = (value: number) => {
    onChange({
      maximumRetention: {
        enabled: true,
        maximumRetention: Math.round(
          dayjs.duration({ [selectedFragment]: value }).asDays()
        ),
      },
    });
  };

  return (
    <>
      <Button ref={anchorRef} onClick={() => setOpen(true)}>
        {duration.humanize()}{' '}
        <i className='material-symbols-arrow-drop-down-rounded text-xl' />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={() => setOpen(false)}
      >
        <Box className='flex flex-col p-[24px] gap-[24px] w-[300px]'>
          <Typography variant='subtitle1'>
            Choose a retention time counter
          </Typography>
          <Select
            value={selectedFragment}
            onChange={(e) =>
              setSelectedFragment(e.target.value as durationFragment)
            }
          >
            {durationOpts.map((option) => (
              <MenuItem key={option} value={option}>
                {capitalize(option)}
              </MenuItem>
            ))}
          </Select>
          <Box className='flex items-center justify-between'>
            How many {capitalize(selectedFragment)}?{' '}
            <TextField
              type='number'
              inputProps={{
                min: 1,
                step: 1,
                pattern: '[0-9]*',
                inputMode: 'numeric',
              }}
              sx={{
                width: 80,
                '& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button':
                  {
                    WebkitAppearance: 'none',
                    margin: 0,
                  },
              }}
              value={durationValue}
              onChange={(e) => update(+e.target.value)}
            />
          </Box>
        </Box>
      </Popover>
    </>
  );
}
