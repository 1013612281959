import type { Theme } from '@mui/material';

import type { VerticalNavState } from '@menu/contexts/verticalNavContext';
import { verticalNavClasses } from '@menu/utils/menuClasses';

const navigationCustomStyles = (
  verticalNavOptions: VerticalNavState,
  theme: Theme
) => {
  // Vars
  const { isCollapsed, isHovered, collapsedWidth, transitionDuration } =
    verticalNavOptions;

  const collapsedHovered = isCollapsed && isHovered;
  const collapsedNotHovered = isCollapsed && !isHovered;

  return {
    color: 'var(--mui-palette-common-white)',
    zIndex: 'var(--drawer-z-index) !important',
    [`& .${verticalNavClasses.header}`]: {
      paddingBlock: theme.spacing(5),
      paddingInline: '15px',
      ...(collapsedNotHovered && {
        paddingInline: '10px',
        '& a': {
          transform: `translateX(-${22 - (collapsedWidth! - 29) / 2}px)`,
        },
      }),
      '& a': {
        transition: `transform ${transitionDuration}ms ease`,
      },
    },
    [`& .${verticalNavClasses.container}`]: {
      transition: theme.transitions.create(
        ['inline-size', 'inset-inline-start', 'box-shadow'],
        {
          duration: transitionDuration,
          easing: 'ease-in-out',
        }
      ),
      borderColor: 'transparent',
      ...(collapsedHovered && {
        boxShadow: 'var(--mui-customShadows-lg)',
      }),
    },
    [`& .${verticalNavClasses.backdrop}`]: {
      backgroundColor: 'var(--backdrop-color)',
    },
  };
};

export default navigationCustomStyles;
