'use client';

import { styled, useTheme } from '@mui/material';

import navigationCustomStyles from '@core/styles/vertical/navigationCustomStyles';
import useVerticalNav from '@menu/hooks/useVerticalNav';
import VerticalNav, { NavHeader } from '@menu/vertical-menu';

import Logo from '@/components/layout/shared/Logo';

import VerticalMenu from './VerticalMenu';

const StyledBoxForShadow = styled('div')(({ theme }) => ({
  top: 60,
  left: -8,
  zIndex: 2,
  opacity: 0,
  position: 'absolute',
  pointerEvents: 'none',
  width: 'calc(100% + 15px)',
  height: theme.mixins.toolbar.minHeight,
  transition: 'opacity .15s ease-in-out',
  background: `linear-gradient(var(--mui-palette-background-default) 5%, rgb(var(--mui-palette-background-defaultChannel) / 0.85) 30%, rgb(var(--mui-palette-background-defaultChannel) / 0.5) 65%, rgb(var(--mui-palette-background-defaultChannel) / 0.3) 75%, transparent)`,
  '&.scrolled': {
    opacity: 1,
  },
}));

const Navigation = () => {
  const verticalNavOptions = useVerticalNav();
  const theme = useTheme();

  return (
    // Sidebar Vertical Menu
    <VerticalNav
      customStyles={navigationCustomStyles(verticalNavOptions, theme)}
    >
      {/* Nav Header including Logo & nav toggle icons  */}
      <NavHeader>
        <Logo />
      </NavHeader>
      <StyledBoxForShadow />
      <VerticalMenu />
    </VerticalNav>
  );
};

export default Navigation;
